/* eslint-disable array-callback-return */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

function RecordDataRecommendedSection(props) {
  const tableHeadStyling = {
    border: 'solid',
    borderColor: 'grey',
    padding: 10,
    fontWeight: 'bold',
  };
  const tableBodyStyling = {
    border: 'solid',
    borderColor: 'grey',
    padding: 10,
    wordBreak: 'break-word',
    textAlign: 'center',
  };
  const tableBodyStylingLeftAlign = {
    border: 'solid',
    borderColor: 'grey',
    padding: '10px 10px 10px 5%',
    wordBreak: 'break-word',
    textAlign: 'left',
  };
  const tableWidth = '68%';

  const columns = [
    {
      id: 'vaccineName',
      Cell: ({ value }) => <b>{value}</b>,
      align: 'center',
      width: '27.5%',
    },
    {
      id: 'series',
      align: 'center',
      width: '15%',
    },
    {
      id: 'dateGiven',
      align: 'center',
      width: '15%',
    },
    {
      id: 'ageGiven',
      align: 'center',
      width: '15%',
    },
    {
      id: 'clinicName',
      align: 'center',
      width: '27.5%',
    },
  ];

  return (
    <Table
      style={{
        width: tableWidth,
        borderStyle: 'solid',
        tableLayout: 'fixed',
      }}
    >
      <TableHead style={{ backgroundColor: '#E1E0E3', padding: 10 }}>
        <TableCell
          key={Math.random()}
          align={columns[0].align}
          width={columns[0].width}
          style={tableHeadStyling}
        >
          Vaccine Group
        </TableCell>
        <TableCell
          key={Math.random()}
          align={columns[0].align}
          width={columns[0].width}
          style={tableHeadStyling}
        >
          Recommended Date
        </TableCell>
      </TableHead>

      <TableBody>
        {props.record.emptyGroups.map((group) => {
          let { recommendedDate } = group.nextDose;
          if (group.nextDose.isPatientDiscretion === 'true') {
            recommendedDate = <i>Consult your healthcare provider</i>;
          }

          if (group.nextDose != null)
            return (
              <TableRow tabIndex={-1} key={group.code}>
                <TableCell key={columns.id} style={tableBodyStylingLeftAlign}>
                  <span>
                    <svg height="15" width="15">
                      <circle
                        cx="7.5"
                        cy="7.5"
                        r="5"
                        stroke="black"
                        strokeWidth={props.getStrokeWidth(group.nextDose)}
                        fill={props.getEvaluatorStatusColor(group.nextDose)}
                      />
                    </svg>
                    {props.displayVaccineGroupName(group)}
                  </span>
                </TableCell>
                <TableCell key={columns.id} style={tableBodyStyling}>
                  {recommendedDate}
                </TableCell>
              </TableRow>
            );
        })}
      </TableBody>
    </Table>
  );
}

export default RecordDataRecommendedSection;
