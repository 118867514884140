import React from 'react';
import { Trans } from 'react-i18next';
import ReactGA from 'react-ga';
import { PDFDownloadLink } from '@react-pdf/renderer';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

// component imports
// import PrintIcon from '@material-ui/icons/Print';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Button } from '@material-ui/core';
import PDFFile from './PDFFile';

// style imports
import AppController from '../utils/AppController';
import '../styles/RecordData-style.css';

// eslint-disable-next-line no-unused-vars
import '../i18n';
// eslint-disable-next-line no-unused-vars
import AppleHealthButton from './AppleHealthButton';

function RecordDataFooter({
  record,
  displayVaccineGroupName,
  displayEvaluatorStatus,
  isVersionGood,
  apple,
  isMobile,
  user,
  settings,
}) {
  const imgSource = '/imgs/add-to-apple-health.svg';
  const altText = 'Add to Apple Wallet & Health';

  const appInsights = useAppInsightsContext();

  const pressVat = () => {
    appInsights.trackEvent({ name: 'All Vaccines Record VAT Link Pressed' });
  };

  const pressPDF = () => {
    appInsights.trackEvent({ name: 'DVR Record Download as PDF Pressed' });
  };

  function letterToNumber(letter) {
    if (!letter) {
      return null;
    }
    letter = letter.toUpperCase();
    let result = 0;

    for (let i = 0; i < letter.length; i++) {
      const charValue = letter.charCodeAt(i) - 'A'.charCodeAt(0) + 1;
      result = result * 26 + charValue;
    }

    return result;
  }

  return (
    <div>
      <div
        style={{
          marginBottom: '20px',
          marginTop: '20px',
        }}
      >
        <span
          style={{
            background: '#22489C',
            borderRadius: '5px',
            color: '#ffffff',
            padding: '0px 5px 1px 5px',
          }}
        >
          Note:
        </span>{' '}
        The recommendations displayed on your DVR are based on guidelines from
        the Centers for Disease Control and Prevention (CDC). Your healthcare
        provider may make different vaccine recommendations based on your
        individual risk factors.
        <br />
        <br />
        <span>
          Visit the CDC website to view the most recent
          <ReactGA.OutboundLink
            eventLabel="myturn"
            to="https://www.cdc.gov/vaccines/schedules/index.html"
            target="_blank"
            style={{
              display: 'inline',
              color: '#0D6EFD',
              margin: '0 5px',
              textDecoration: 'underline',
            }}
          >
            recommended vaccination schedules.
          </ReactGA.OutboundLink>
          {AppController.externalLink()}
        </span>
      </div>
      <hr />
      <div style={{ marginTop: '20px' }}>
        <p>
          NOTE: It is possible that some or all vaccine doses you received were
          not reported to the California Immunization Registry (CAIR), and
          therefore your Digital Vaccine Record may not be complete. If you have
          questions about your vaccination record,
          <ReactGA.OutboundLink
            eventLabel="faq"
            to="/faq"
            target="_blank"
            style={{
              display: 'inline',
              color: '#0D6EFD',
              margin: '0 5px',
              textDecoration: 'underline',
            }}
          >
            visit our FAQ.
          </ReactGA.OutboundLink>
          {AppController.externalLink()}
        </p>
      </div>

      {record.evaluatorAvailable && (
        <p>
          <span>
            <svg height="15" width="15">
              <circle
                cx="7.5"
                cy="7.5"
                r="5"
                stroke="black"
                strokeWidth="0"
                fill="#009227"
              />
            </svg>
            &nbsp;Symbolizes that based on CDC recommendations the vaccine group
            is up to date.
          </span>
          <br />
          <span>
            <svg height="15" width="15">
              <circle
                cx="7.5"
                cy="7.5"
                r="5"
                stroke="black"
                strokeWidth="1.5"
                fill="#FDB81E"
              />
            </svg>
            &nbsp;Symbolizes that the next dose for the vaccine group is
            upcoming and medically recommended.
          </span>
          <br />
          <span>
            <svg height="15" width="15">
              <circle
                cx="7.5"
                cy="7.5"
                r="5"
                stroke="black"
                strokeWidth="0"
                fill="#D30F00"
              />
            </svg>
            &nbsp;Symbolizes that the next dose for the vaccine group is overdue
            and medically recommended.
          </span>
          <br />
          <span>
            <svg height="15" width="15">
              <circle
                cx="7.5"
                cy="7.5"
                r="5"
                stroke="black"
                strokeWidth="1.5"
                fill="#A3D8F4"
              />
            </svg>
            &nbsp;Symbolizes to consult your healthcare provider.
          </span>
          <br />
        </p>
      )}

      <ol>
        <li>
          <i>Invalid</i> means that a dose was not given according to the
          medical schedule. But, the dose may still meet school requirements.
        </li>
        {record.invalidDoseReasons && record.evaluatorAvailable && (
          <ol type="A">
            {record.invalidDoseReasons.map((invalidDoseReason) => (
              <li
                value={letterToNumber(
                  invalidDoseReason.invalidSuperScriptLabel,
                )}
              >
                {invalidDoseReason.invalidReason}
              </li>
            ))}
          </ol>
        )}
        <li>
          <i>N/A</i> means that the dose has missing information in the registry
          and was excluded from vaccine forecasting.
        </li>
        <li>
          <i>Complete or Aged out</i> means that a dose is not medically needed.
        </li>
        <li>
          <i>Transcribed by</i> indicates that the clinic entered the
          information to CAIR but did not administer that dose.
        </li>
        <li>
          <i>Combination vaccines</i> are listed multiple times - once for each
          disease they protect against.
        </li>
        <li>
          <i>Extra Dose</i> means that the immunization was administered as an
          extra dose.
        </li>
      </ol>

      <span>Definitions:</span>
      <ul style={{ listStyleType: 'none' }}>
        <li>
          {' '}
          COVID-19: <i>Coronavirus</i>
        </li>
        <li>
          {' '}
          DT, Td: <i>diphtheria, tetanus</i>
        </li>
        <li>
          {' '}
          DTaP, Tdap: <i>diphtheria, tetanus, pertussis (whooping cough)</i>
        </li>
        <li>
          {' '}
          Flu: <i>influenza (only the most recent 3 doses are listed)</i>
        </li>
        <li>
          {' '}
          HepA: <i>hepatitis A</i>
        </li>
        <li>
          {' '}
          HepB: <i>hepatitis B</i>
        </li>
        <li>
          {' '}
          Hib: <i>haemophilus influenza B (hib meningitis)</i>
        </li>
        <li>
          {' '}
          HPV: <i>human papillomavirus</i>
        </li>
        <li>
          {' '}
          HZV, RZV, ZVL: <i>herpes zoster (shingles)</i>
        </li>
        <li>
          {' '}
          IPV, OPV: <i>polio</i>
        </li>
        <li>
          {' '}
          MenACWY (MCV4), MenB, MPSV: <i>meningococcal</i>
        </li>
        <li>
          {' '}
          MMR: <i>measles, mumps, rubella</i>
        </li>
        <li>
          {' '}
          PCV, PPSV: <i>pneumococcal (pneumonia)</i>
        </li>
        <li>
          {' '}
          Rota, RV: <i>rotavirus</i>
        </li>
        <li>
          {' '}
          S-pox: <i>Smallpox</i>
        </li>
        <li>
          {' '}
          Td: <i>tetanus</i>
        </li>
        <li>
          {' '}
          VAR: <i>varicella (chickenpox)</i>
        </li>
        <li>
          {' '}
          YF: <i>Yellow Fever</i>
        </li>
      </ul>

      <div className="save-button-container" tabIndex={-1}>
        <div>
          <PDFDownloadLink
            document={
              <PDFFile
                record={record}
                displayVaccineGroupName={displayVaccineGroupName}
                displayEvaluatorStatus={displayEvaluatorStatus}
              />
            }
            fileName="digitalvaccinerecord.pdf"
          >
            <Button
              id="save-button-style"
              className="button-hover"
              variant="contained"
              startIcon={<SaveAltIcon />}
              color="primary"
              size="large"
              onClick={(_e) => {
                pressPDF();
              }}
              tabIndex={-1}
            >
              Download as PDF
            </Button>
          </PDFDownloadLink>
        </div>
        {isVersionGood && apple === true && isMobile() === 'A' ? (
          <AppleHealthButton
            user={user}
            imgSource={imgSource}
            altText={altText}
          />
        ) : null}
      </div>

      <div>
        <h2
          style={{
            color: '#22489c',
            margin: '30px 0 10px 0',
            fontSize: '130%',
          }}
        >
          <Trans i18nKey="recordpage.needhelp">Need Help?</Trans>
        </h2>

        <p className="pt-2">
          <Trans i18nKey="recordpage.incorrect">
            If your record is not correct, please visit the CDPH virtual
            assistant to submit for a record review and update.
          </Trans>
        </p>
        <Trans i18nKey="recordpage.linkto">Link To: </Trans>
        <ReactGA.OutboundLink
          eventLabel="virtual_assistant"
          to={settings.VATLink}
          target="_blank"
          style={{
            color: '#0d6efd',
            margin: '0',
            textDecoration: 'underline',
          }}
          onClick={(_e) => {
            pressVat();
          }}
        >
          <Trans i18nKey="recordpage.virtualassistant">Virtual Assistant</Trans>
        </ReactGA.OutboundLink>
        {AppController.externalLink()}
      </div>
      <br />
    </div>
  );
}

export default RecordDataFooter;
