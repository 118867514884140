import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

function InvalidPage() {
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    pageLoaded();
  }, []);

  const pageLoaded = () => {
    appInsights.trackEvent({ name: 'Invalid URL page loaded' });
  };
  return (
    <div className="invalid-page-container bodyContainer">
      <section>
        <article>
          <div style={{ marginTop: '100px' }}>
            <p>
              <Trans i18nKey="invalidPage.message">
                {/* You've passed the 24-hour limit to retrieve your DVR. Please start over at the{""} */}

                <a
                  href="https://myvaccinerecord.cdph.ca.gov/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: '#0d6efd',
                    margin: '0',
                    textDecoration: 'underline',
                  }}
                >
                  DVR site
                </a>
              </Trans>
            </p>
          </div>
        </article>
      </section>
    </div>
  );
}

export default InvalidPage;
