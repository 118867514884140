import React, { useEffect } from 'react';
import RecordDataRecommendedSection from './RecordDataRecommendedSection';
import VaccineGroupTable from './VaccineGroupTable';

const DesktopDVRData = ({
  record,
  getCircleStrokeWidth,
  getEvaluatorStatusColor,
  displayVaccineGroupName,
  displayEvaluatorStatus,
  myTurnBannerGroupCodes,
  setMyTurnBannerGroupCodes,
}) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    UpdateBannerGroupCodes();
  }, []);

  function UpdateBannerGroupCodes() {
    const groupCodes = [];
    for (const vaccineGroup of record.vaccineGroups) {
      if (
        vaccineGroup.displayForecast &&
        vaccineGroup.nextDose &&
        (vaccineGroup.nextDose.status === 'RECOMMENDED' ||
          vaccineGroup.nextDose.status === 'FUTURE_RECOMMENDED') &&
        !myTurnBannerGroupCodes.includes(vaccineGroup.groupCode)
      ) {
        groupCodes.push(vaccineGroup.groupCode);
      }
    }

    for (const vaccineGroup of record.emptyGroups) {
      if (
        vaccineGroup.displayForecast &&
        vaccineGroup.nextDose &&
        (vaccineGroup.nextDose.status === 'RECOMMENDED' ||
          vaccineGroup.nextDose.status === 'FUTURE_RECOMMENDED') &&
        !myTurnBannerGroupCodes.includes(vaccineGroup.groupCode)
      ) {
        groupCodes.push(vaccineGroup.groupCode);
      }
    }
    setMyTurnBannerGroupCodes(groupCodes);
  }

  return (
    <div>
      {record.vaccineGroups.map((vaccineGroup) => (
        // display vaccine group name
        <div>
          <span className="vaccine-name-style" style={{ float: 'left' }}>
            {displayVaccineGroupName(vaccineGroup)}
          </span>
          {record.evaluatorAvailable && vaccineGroup.displayForecast && (
            <span className="vaccine-name-style" style={{ float: 'right' }}>
              {displayEvaluatorStatus(vaccineGroup.nextDose)}
              <svg height="15" width="15">
                <circle
                  cx="7.5"
                  cy="7.5"
                  r="5"
                  stroke="black"
                  strokeWidth={getCircleStrokeWidth(vaccineGroup.nextDose)}
                  fill={getEvaluatorStatusColor(vaccineGroup.nextDose)}
                />
              </svg>
            </span>
          )}

          {/* display vaccine group table */}
          <VaccineGroupTable group={vaccineGroup} record={record} />
        </div>
      ))}
      <br />

      {/* display table for recommended vaccine groups */}
      {record &&
        record.evaluatorAvailable &&
        record.emptyGroups &&
        record.emptyGroups.length > 0 && (
          <div>
            <hr />
            <h1
              style={{
                fontWeight: 'bold',
                fontSize: 24,
                paddingBottom: 5,
                paddingTop: 20,
              }}
            >
              Recommended vaccinations we do not see in your record (future
              recommendations shown in yellow are due within one year):
            </h1>
            <RecordDataRecommendedSection
              record={record}
              displayVaccineGroupName={displayVaccineGroupName}
              displayEvaluatorStatus={displayEvaluatorStatus}
              getEvaluatorStatusColor={getEvaluatorStatusColor}
              getStrokeWidth={getCircleStrokeWidth}
            />
          </div>
        )}
    </div>
  );
};

export default DesktopDVRData;
