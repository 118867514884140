const faqLinkLanguage = () => '/faq';
// switch (i18n.resolvedLanguage ? i18n.resolvedLanguage.toLowerCase() : undefined) {
//   case 'en':
//     return '/faq';
//   case 'es':
//     return '/faq-es';
//   case 'zh':
//     return '/faq-ch ';
//   case 'cn':
//     return '/faq-ch';
//   case 'tw':
//     return '/faq-ch-hant';
//   case 'ph':
//     return '/faq-tg';
//   case 'kr':
//     return '/faq-ko';
//   case 'ar':
//     return '/faq-ar';
//   case 'ae':
//     return '/faq-ar';
//   case 'ko':
//     return '/faq-ko';
//   case 'tl':
//     return '/faq-tg';
//   case 'zh-tw':
//     return '/faq-ch-hant';
//   case 'vi':
//     return '/faq-vi';
//   default:
//     return '/faq';
// }

export default faqLinkLanguage;
