import { Trans, useTranslation } from 'react-i18next';
// import React, { useEffect, useState } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import FederalAgencyPaneCss from '../styles/FederalAgencyPane.module.css';

export function FederalAgencyPaneContent(props) {
  const appInsights = useAppInsightsContext();
  const pressFaq = () => {
    appInsights.trackEvent({ name: 'Vaccinations Pane Q&A Link Pressed' });
  };

  const { _i18n } = useTranslation();

  return (
    <div
      className={FederalAgencyPaneCss.root}
      aria-hidden={props.index === props.activeIndex ? 'false' : 'true'}
    >
      <div className={FederalAgencyPaneCss.container}>
        <div className={FederalAgencyPaneCss.imageContainer}>
          <img
            className={FederalAgencyPaneCss.superClinicsImg}
            aria-label="patient receiving a vaccination"
            src="/imgs/carousel_pane2.svg"
          />
        </div>
        <div className={FederalAgencyPaneCss.textContainer}>
          <div className={FederalAgencyPaneCss.title}>
            <Trans i18nKey="landingPage.federalAgencyHeader">
              Federal Agency Vaccinations
            </Trans>
          </div>
          <p className={FederalAgencyPaneCss.text}>
            <Trans i18nKey="landingPage.federalAgencyBody">
              {/* If you received your vaccinations from a federal agency (e.g., Department of Defense, Indian Health Services, or Veterans Affairs), you may need to contact those agencies for assistance with your immunization record. If you have questions about your immunization record, {" "} */}
              <a
                href="/faq"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={props.index === props.activeIndex ? 0 : -1}
                style={{
                  color: '#0d6efd',
                  margin: '0',
                  textDecoration: 'underline',
                }}
                onClick={pressFaq}
              >
                visit our Q&A
              </a>
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FederalAgencyPaneContent;
