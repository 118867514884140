import * as React from 'react';
import { Box, Button, Modal, Grid } from '@material-ui/core';

import '../styles/MobileView-style.css';

export default function VaccineGroupModal({
  group,
  record,
  displayVaccineGroupName,
  displayEvaluatorStatus,
  getEvaluatorStatusColor,
  settings,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function displayRecommendationMessage(group) {
    return group.nextDose.isPatientDiscretion === 'true'
      ? 'Consult your healthcare provider to see if this vaccination is recommended for you based on your individual risk factors.'
      : 'Doses have not been reported and are recommended.';
  }

  const { evaluatorAvailable } = record;
  const borderColor = !evaluatorAvailable
    ? '#AAAAAA'
    : group.displayForecast
      ? getEvaluatorStatusColor(group.nextDose)
      : null;

  function isNA(series) {
    return series === 'N/A';
  }

  function isInvalidSeries(dose) {
    if (evaluatorAvailable && dose.series === 'Invalid') {
      return (
        <span>
          {dose.series}
          {dose.invalidSuperscriptLabel && (
            <sup>1{dose.invalidSuperscriptLabel}</sup>
          )}
        </span>
      );
    }

    return <span>{dose.series}</span>;
  }

  return (
    <div>
      <Button
        id="button-style"
        fullWidth
        style={{
          borderColor,
          justifyContent: 'flex-start',
          padding: '15px',
        }}
        onClick={handleOpen}
      >
        {displayVaccineGroupName(group)}
      </Button>
      <Modal
        open={open}
        scrollable
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="modal-container">
          {/* modal vaccine group title */}
          <div
            className="modal-vaccine-title-container"
            style={{ borderColor }}
          >
            <span>
              <Grid
                container
                justifyContent="space-between"
                direction="row-reverse"
                alignItems="center"
              >
                <Grid item xs={2} space={1}>
                  <Button onClick={handleClose} style={{ float: 'right' }}>
                    <img
                      className="x-image-style"
                      src="/imgs/x.svg"
                      alt="x for banner"
                    />
                  </Button>
                </Grid>
                <Grid item style={{ textAlign: 'center' }} xs={9}>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: '18px',
                      maxWidth: '20wh',
                      textAlign: 'center',
                    }}
                  >
                    {displayVaccineGroupName(group)}
                  </span>
                </Grid>
              </Grid>
            </span>
          </div>

          {/* modal personal info header */}
          <div className="modal-vaccine-info-container">
            <div className="modal-pers-info-container">
              <div>
                <span className="modal-pers-info-container-header">
                  <span style={{ display: 'inline', fontWeight: 'bold' }}>
                    {record.firstName} {record.lastName}{' '}
                  </span>
                </span>
                <br />
                <span>
                  <span style={{ display: 'inline', fontWeight: 'bold' }}>
                    Date of Birth:{' '}
                  </span>{' '}
                  {record.dob}
                </span>
                <br />
                <span>
                  <span style={{ display: 'inline', fontWeight: 'bold' }}>
                    Record Issued On:{' '}
                  </span>{' '}
                  {record.currentDate}
                </span>
                <br />
                {record.evaluatorAvailable && group.displayForecast && (
                  <div>
                    {group.nextDose.isPatientDiscretion === 'false' && <br />}
                    <span
                      style={{
                        display: 'inline',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}
                    >
                      {displayEvaluatorStatus(group.nextDose)}
                    </span>
                  </div>
                )}
              </div>
            </div>

            {/* line here */}
            <hr />

            {/* modal vaccine group doses */}
            {group.existingDoses && group.existingDoses.length > 0 && (
              <div className="modal-vaccine-doses-container">
                <div className="modal-vaccine-doses-scroll">
                  {group.existingDoses.map((dose) => (
                    <div className="modal-vaccine-doses">
                      <span className="modal-vaccine-doses-header">
                        <span style={{ display: 'inline', fontWeight: 'bold' }}>
                          {dose.vaccineName}
                        </span>
                      </span>
                      <br />
                      <span>
                        {evaluatorAvailable ? (
                          <span
                            style={{ display: 'inline', fontWeight: 'bold' }}
                          >
                            Dose:{' '}
                          </span>
                        ) : (
                          <span
                            style={{ display: 'inline', fontWeight: 'bold' }}
                          >
                            Series:{' '}
                          </span>
                        )}

                        {isNA(dose.series) ? (
                          <span>
                            {dose.series}
                            <sup>2</sup>
                          </span>
                        ) : (
                          isInvalidSeries(dose)
                        )}
                      </span>
                      <br />
                      <span>
                        <span style={{ display: 'inline', fontWeight: 'bold' }}>
                          Date Given:{' '}
                        </span>
                        {dose.dateGiven}
                      </span>
                      <br />
                      <span>
                        <span style={{ display: 'inline', fontWeight: 'bold' }}>
                          Age Given:{' '}
                        </span>
                        {dose.ageGiven}
                      </span>
                      <br />
                      <span>
                        <span style={{ display: 'inline', fontWeight: 'bold' }}>
                          Clinic:{' '}
                        </span>
                        {dose.clinicName}
                      </span>
                      {
                        dose.invalidReasonText !== '' && (
                          <span>
                            <br />
                            <span
                              style={{ display: 'inline', fontWeight: 'bold' }}
                            >
                              Note:{' '}
                            </span>
                            {dose.invalidReasonText}
                          </span>
                        )
                        /* eslint-disable prettier/prettier */
                      }
                      <br />
                      <br />
                    </div>
                    /* eslint-enable prettier/prettier */
                  ))}
                </div>
                {group.displayForecast &&
                group.nextDose &&
                settings &&
                settings.MyTurnBannerStatus &&
                settings.MyTurnBannerVaccineGroups &&
                group.groupCode in settings.MyTurnBannerVaccineGroups &&
                /* eslint-disable prettier/prettier */
                (group.nextDose.status === 'RECOMMENDED' |
                  group.nextDose.status === 'FUTURE_RECOMMENDED') ? (
                    /* eslint-enable prettier/prettier */
                  <div className="myTurnFooter">
                    <div>
                      <span className="myTurnText">
                        Need to schedule your next vaccine appointment?
                      </span>
                    </div>
                    <div>
                      <a
                        href={settings.MyTurnLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          type="button"
                          className="buttonStyle"
                          href={settings.MyTurnLink}
                        >
                          Visit My Turn
                        </button>
                      </a>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            {group.existingDoses.length === 0 && (
              <div className="modal-recommendation-container">
                <span>{displayRecommendationMessage(group)}</span>
                <br />
                <br />
                {group.displayForecast &&
                group.nextDose &&
                settings &&
                settings.MyTurnBannerStatus &&
                settings.MyTurnBannerVaccineGroups &&
                group.groupCode in settings.MyTurnBannerVaccineGroups &&
                /* eslint-disable prettier/prettier */
                (group.nextDose.status === 'RECOMMENDED' | group.nextDose.status === 'FUTURE_RECOMMENDED') ? (   
                  /* eslint-enable prettier/prettier */
                  <div className="myTurnFooter">
                    <div>
                      <span className="myTurnText">
                        Need to schedule your next vaccine appointment?
                      </span>
                    </div>
                    <div>
                      <a
                        href={settings.MyTurnLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          className="buttonStyle"
                          type="button"
                          href={settings.MyTurnLink}
                        >
                          Visit My Turn
                        </button>
                      </a>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
