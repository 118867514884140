import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import VaccinationsPaneCss from '../styles/VaccinationsPane.module.css';

export function VaccinationsPaneContent(props) {
  const { _i18n } = useTranslation();

  return (
    <div
      className={VaccinationsPaneCss.root}
      aria-hidden={props.index === props.activeIndex ? 'false' : 'true'}
    >
      <div className={VaccinationsPaneCss.container}>
        {window.innerWidth <= 950 && (
          <div className={VaccinationsPaneCss.imageContainer}>
            <img
              className={VaccinationsPaneCss.superClinicsImg}
              aria-label="group of people taking a walk"
              src="/imgs/carousel_pane1.svg"
            />
          </div>
        )}
        <div className={VaccinationsPaneCss.textContainer}>
          <div className={VaccinationsPaneCss.title}>
            <Trans i18nKey="landingPage.vaccinationsHeader">
              Vaccinations on your DVR
            </Trans>
          </div>
          <p className={VaccinationsPaneCss.text}>
            <Trans i18nKey="landingPage.vaccinationsBody">
              It’s possible that some or all vaccine doses you received were not
              reported to the California Immunization Registry (CAIR), so your
              DVR may not be complete. If your record is incomplete, please ask
              your provider to submit your vaccine information to CAIR so we can
              update your record.
            </Trans>
          </p>
          {/* <p className={VaccinationsPaneCss.text}>
                        <Trans i18nKey="landingPage.instructions">
                            Make sure your provider has your most up-to-date information (phone, email, first and last name) so you can access your complete record.
                        </Trans>
                    </p> */}
        </div>
        {window.innerWidth > 950 && (
          <div className={VaccinationsPaneCss.imageContainer}>
            <img
              className={VaccinationsPaneCss.superClinicsImg}
              aria-label="group of people taking a walk"
              src="/imgs/carousel_pane1.svg"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default VaccinationsPaneContent;
