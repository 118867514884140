import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  withAITracking,
  useAppInsightsContext,
} from '@microsoft/applicationinsights-react-js';

import faqLinkLanguage from '../utils/faqLinkLanguage';

import { reactPlugin } from '../utils/ApplicationInsightsService';

function ReceivedScreen() {
  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    pageLoaded();
  }, []);

  const appInsights = useAppInsightsContext();

  const pageLoaded = () => {
    appInsights.trackEvent({ name: 'Submission receipt page loaded' });
  };

  return (
    <div className="received-screen-container bodyContainer">
      <section>
        <div>
          <h1
            style={{
              color: '#22489C',
              fontSize: '45px',
              marginTop: '25px',
              marginBottom: '0px',
              fontWeight: 'bold',
            }}
          >
            <Trans i18nKey="receivedPage.thankYou">Thank you.</Trans>
          </h1>
          <h1
            style={{
              color: '#22489C',
              fontSize: '45px',
              marginBottom: '25px',
              fontWeight: 'bold',
            }}
          >
            <Trans i18nKey="receivedPage.title">
              We've received your submission.
            </Trans>
          </h1>
        </div>
        <article>
          <div style={{ marginBottom: '30px' }}>
            <p>
              <Trans i18nKey="receivedPage.content1">
                If the information provided matches the vaccination registry,
                you will receive a link to access your vaccination record.
              </Trans>
            </p>

            <p>
              <Trans i18nKey="receivedPage.content3">
                If you have questions about your vaccine record,{' '}
                <a
                  href={faqLinkLanguage()}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: '#0d6efd',
                    margin: '0',
                    textDecoration: 'underline',
                  }}
                >
                  visit our Q&A
                </a>
                .
              </Trans>
            </p>
          </div>
        </article>
      </section>
    </div>
  );
}

export default withAITracking(reactPlugin, ReceivedScreen, 'Received Screen');
