import React, { useEffect, useState, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Trans, useTranslation } from 'react-i18next';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PhoneMask from './PhoneMask';
import '../styles/RequestForm-style.css';

import { appInsights } from '../utils/ApplicationInsightsService';

function RequestForm({ _htmlDir }) {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedBirthDate, setSelectedBirthDate] = useState(null);
  const [contactType, setContactType] = useState('Phone');
  const [requestType, setRequestType] = useState('');
  const [fieldMasks, setFieldMasks] = useState({
    textmask: '(  )    -    ',
  });
  const [checked, setChecked] = useState(false);
  const [secondChecked, setSecondChecked] = useState(false);
  const [isDobGood, setIsDobGood] = useState(true);
  const [pin, setPin] = useState('');
  const [errorMessage, setErrorMessage] = useState({});
  const [fNameErrorMessage, setFNameErrorMessage] = useState({});
  const [lNameErrorMessage, setLNameErrorMessage] = useState({});
  const [emailErrorMessage, setEmailErrorMessage] = useState({});
  const [responseMessage, setResponseMessage] = useState(false);
  const [error, setError] = useState({
    FirstName: false,
    LastName: false,
    Phone_Email: false,
    RequestType: false,
    Pin: false,
    Date: false,
  });

  const [value, setValue] = useState({
    FirstName: '',
    LastName: '',
    Phone: '',
    Email: '',
    Date: false,
  });

  const history = useHistory();
  const { CREDENTIALS_API_STATUS } = window.config;
  const emailRegex =
    new RegExp(`^([a-zA-Z0-9.!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z
    0-9])?\\.)+[a-zA-Z]{2,}|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?=[-0-9a-zA-Z._]*\\])|(?:(?:[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)*)*)$`);
  const inputRegex = new RegExp(/^[a-zA-Z0-9\-.'",\s)(\*+]+$/);
  const noWhiteSpaceRegex = new RegExp(/^\S*$/);
  const alignment =
    i18n.resolvedLanguage?.toLowerCase() === 'ae' ||
    i18n.resolvedLanguage?.toLowerCase() === 'ar'
      ? '5px'
      : '0px';

  const rightOrLeft =
    i18n.resolvedLanguage?.toLowerCase() === 'ae' ||
    i18n.resolvedLanguage?.toLowerCase() === 'ar'
      ? 'right'
      : 'left';

  useEffect(() => {
    document
      .querySelectorAll('.MuiButtonBase-root')[1]
      .setAttribute('aria-label', 'Date of birth');
    document
      .getElementById('submitcheckbox')
      .setAttribute('aria-label', 'Acknowledge Button');
    document
      .getElementById('partitioned')
      .setAttribute('aria-label', 'Set 4 Digit Pin');
    pageLoaded();
  }, []);

  const pageLoaded = () => {
    appInsights.trackEvent({ name: 'Request Form Loaded' });
  };

  const checkFormErrors = () => {
    const hasErrors = Object.keys(error).some((k) => error[k]);
    return (
      hasErrors ||
      emailErrorMessage.type === 'emailErrorMsg' ||
      fNameErrorMessage.type === 'nameErrorMsg' ||
      lNameErrorMessage.type === 'nameErrorMsg'
    );
  };

  const normalize = (phone) => {
    // normalize string and remove all unnecessary characters
    phone = phone.replace(/[^\d]/g, '');

    // check if number length equals to 10
    if (phone.length === 10) {
      // reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }

    return null;
  };

  const handleChange = (_e) => {
    finalCheck();
    setChecked(!checked);
  };

  const handleSecondCheckChange = (_e) => {
    finalCheck();
    setSecondChecked(!secondChecked);
  };

  const checkEmail = (e) => {
    if (emailRegex.test(e)) {
      const tld = e.split('.').pop();
      if (!/^\d/.test(tld) && /^[a-zA-Z0-9-]+$/.test(tld)) {
        // Valid email address
        setEmailErrorMessage({});
        return true;
      }
    } else {
      setEmailErrorMessage({
        type: 'emailErrorMsg',
        message: 'Please enter a valid email address',
      });
      return false;
    }
  };

  const checkFName = (value) => {
    if (inputRegex.test(value) || !value) {
      setFNameErrorMessage({});
      return true;
    }
    setFNameErrorMessage({
      type: 'nameErrorMsg',
      message: 'Please enter only valid special characters: . - \' ",)(*+"',
    });
  };

  const checkLName = (value) => {
    if (inputRegex.test(value) || !value) {
      setLNameErrorMessage({});
      return true;
    }
    setLNameErrorMessage({
      type: 'nameErrorMsg',
      message: 'Please enter only valid special characters: . - \' ",)(*+"',
    });
  };

  const finalCheck = () => {
    const tempErrorObj = {
      FirstName: false,
      LastName: false,
      Phone_Email: false,
      Pin: false,
      Date: false,
      RequestType: false,
    };
    if (
      document.getElementById('FirstName')
        ? document.getElementById('FirstName').value.trim().length < 1
        : ''
    ) {
      tempErrorObj.FirstName = true;
    }
    if (
      document.getElementById('LastName')
        ? document.getElementById('LastName').value.trim().length < 1
        : ''
    ) {
      tempErrorObj.LastName = true;
    }
    if (
      document.getElementById('contactPhone')
        ? document.getElementById('contactPhone').value.replace(/[^0-9]/g, '')
            .length < 10
        : '' ||
          !(
            checkEmail(
              document.getElementById('contactEmail')
                ? document.getElementById('contactEmail').value
                : '',
            ) &&
            noWhiteSpaceRegex.test(
              document.getElementById('contactEmail')
                ? document.getElementById('contactEmail').value
                : '',
            )
          )
    ) {
      tempErrorObj.Phone_Email = true;
    }
    if (document.getElementById('partitioned').value.length < 4) {
      tempErrorObj.Pin = true;
      document.getElementById('partitioned').style.background =
        'repeating-linear-gradient(90deg, #f44336 0, #f44336 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat';
    }
    if (
      document.getElementById('dob')
        ? document.getElementById('dob').value.length < 1
        : ''
    ) {
      tempErrorObj.Date = true;
    }

    if (requestType !== 'DVR' && requestType !== 'DCVR') {
      tempErrorObj.RequestType = true;
    }

    setError(tempErrorObj);
  };

  const submitForm = (e) => {
    e.preventDefault();
    const { LastName, FirstName, DateOfBirth, textmask, contactType, PIN } =
      e.target.elements;
    const userData = {
      LastName: LastName.value.trim(),
      FirstName: FirstName.value.trim(),
      DateOfBirth: DateOfBirth.value,
      PhoneNumber: textmask ? normalize(textmask.value) : '',
      EmailAddress: contactType ? contactType.value : '',
      // RequestType: requestType === 'DVR' ? 'A' : requestType === 'DCVR' ? 'C' : requestType === 'Both' ? 'B' : "",
      Pin: PIN.value,
      Language: i18n.resolvedLanguage,
    };

    setLoading(true);

    appInsights.trackEvent({
      name: `Submission Button Pressed - ${requestType} - ${
        contactType ? 'Email' : 'Phone'
      }`,
    });

    if (requestType === 'DVR') {
      fetch(`${CREDENTIALS_API_STATUS}/vaccineCredentialStatusDvr`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      })
        .then((response) => {
          if (response.status === 200) {
            history.push('/received');
          } else if (response.status === 422) {
            setLoading(false);
            setResponseMessage({
              type: 'pinErrorMsg7',
              message: 'Invalid data format, please try again.',
            });
          } else if (response.status === 429) {
            setLoading(false);
            setResponseMessage({
              type: 'pinErrorMsg4',
              message: 'Please try your request again in 1 minute.',
            });
          } else if (response.status !== 200) {
            setLoading(false);
            setResponseMessage({
              type: 'pinErrorMsg6',
              message: 'Could not complete your request, please try again.',
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          setResponseMessage({
            type: 'pinErrorMsg6',
            message: 'Could not complete your request, please try again.',
          });
          appInsights.trackException(error, {
            name: 'DVR Landing Page: No response',
          });
        });
    } else if (requestType === 'DCVR') {
      fetch(`${CREDENTIALS_API_STATUS}/vaccineCredentialStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      })
        .then((response) => {
          if (response.status === 200) {
            history.push('/received');
          } else if (response.status === 422) {
            setLoading(false);
            setResponseMessage({
              type: 'pinErrorMsg7',
              message: 'Invalid data format, please try again.',
            });
          } else if (response.status === 429) {
            setLoading(false);
            setResponseMessage({
              type: 'pinErrorMsg4',
              message: 'Please try your request again in 1 minute.',
            });
          } else if (response.status !== 200) {
            setLoading(false);

            setResponseMessage({
              type: 'pinErrorMsg6',
              message: 'Could not complete your request, please try again.',
            });
          }
        })
        .catch((error) => {
          setLoading(false);

          setResponseMessage({
            type: 'pinErrorMsg6',
            message: 'Could not complete your request, please try again.',
          });
          appInsights.trackException(error, {
            name: 'DCVR Landing Page: No response',
          });
        });
    }
  };

  const numbersOnly = (e) => {
    if (e.target.value.length === 4) {
      e.target.style.background =
        'repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat';
      setError({ ...error, Pin: false });
    }
    if (containsDuplicateChar(e.target.value)) {
      setErrorMessage({
        type: 'pinErrorMsg2',
        message: 'PIN cannot contain 4 duplicate numbers.',
      });
    } else if (containsAscending(e.target.value)) {
      setErrorMessage({
        type: 'pinErrorMsg1',
        message: 'PIN cannot contain 4 consecutive numbers.',
      });
    } else {
      setErrorMessage({});
    }
    const numsOnly = e.target.value.replace(/[^0-9]/g, '');
    setPin(numsOnly);
  };

  const containsAscending = (str) => {
    const strArr = str.split('');
    let ascendingFlag = false;
    for (let i = 0; i < str.length - 2; i++) {
      if (
        parseInt(strArr[i]) + 1 === parseInt(strArr[i + 1]) &&
        parseInt(strArr[i]) + 2 === parseInt(strArr[i + 2]) &&
        parseInt(strArr[i]) + 3 === parseInt(strArr[i + 3])
      ) {
        ascendingFlag = true;
      }
    }
    if (ascendingFlag) {
      return true;
    }
    return false;
  };

  const containsDuplicateChar = (str) => {
    const counts = {};
    let duplicateFlag = false;
    if (str.length > 0) {
      const charArr = str.split('');
      charArr.forEach((n) => {
        counts[n] = counts[n] || 0;
        counts[n]++;
        if (counts[n] === 4) {
          duplicateFlag = true;
        }
      });
    }
    if (duplicateFlag) return true;
    return false;
  };

  const handleContactTypeChange = (event) => {
    setError({ ...error, Phone_Email: true });
    setFieldMasks({ ...fieldMasks, textmask: '' });
    setContactType(event.target.value);
  };

  const handleRequestTypeChange = (event) => {
    setError({ ...error, RequestType: false });
    setRequestType(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setEmailErrorMessage({});
    setValue({ ...value, Phone: true });
    if (event.target.value.replace(/[^0-9]/g, '').length === 10) {
      setError({ ...error, Phone_Email: false });
    } else {
      setError({ ...error, Phone_Email: true });
    }
    setFieldMasks({
      ...fieldMasks,
      [event.target.name]: event.target.value,
    });
  };

  const handleDobChange = (date) => {
    setError({ ...error, Date: false });
    setValue({ ...value, Date: true });
    setSelectedBirthDate(date);
    if (
      date &&
      date.$d &&
      date.$y >= 1900 &&
      new Date(date.$d.toString()) <= Date.now()
    ) {
      setIsDobGood(true);
    } else {
      setIsDobGood(false);
    }
  };

  const handleClickBorder = (e) => {
    e.target.classList.add('no-border');
  };

  const isValidInputFName = (e) => {
    checkFName(e.target.value);
    if (e.type === 'change') {
      return e.target.value.trim().length > 0;
    }

    if (e.type === 'blur') {
      return e.target.value.trim().length < 1;
    }
  };

  const isValidInputLName = (e) => {
    checkLName(e.target.value);
    if (e.type === 'change') {
      return e.target.value.trim().length > 0;
    }

    if (e.type === 'blur') {
      return e.target.value.trim().length < 1;
    }
  };

  const useStyles = makeStyles({
    root: {},
    underline: {
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  });
  const classes = useStyles();

  const displayRequestTypeError = () => {
    if (error.RequestType) {
      return (
        <div>
          Please select an option.
          <br />
        </div>
      );
    }
  };

  return (
    <div className="bodyContainer DashContainer">
      <div className="formBodyContainer">
        <div className="formHeaderContainer">
          <h1 className="formHeaderTitle">
            <Trans i18nKey="requestForm.headerTitle">
              Digital Vaccine Record Request Form
            </Trans>
          </h1>
          <div className="formHeaderSubtitle">
            <Trans i18nKey="requestForm.headerSubtitle">
              Please fill out the required fields to receive a link to your
              Digital Vaccine Record (DVR).
            </Trans>
          </div>
          <br />
          <div className="formHeaderText">
            <Trans i18nKey="requestForm.headerText">
              If you are a parent or guardian and have multiple vaccine records
              associated with a single cell phone number or email address, enter
              each Digital Vaccine Record request separately.
            </Trans>
          </div>
        </div>
        <div className="selectOptionContainer">
          <div className="line">
            <hr />
          </div>
          <h2 className="selectOptionTitle">
            <Trans i18nKey="requestForm.selectOptions">
              Select from one of the options below:
            </Trans>
          </h2>
          <FormControl error={error.RequestType}>
            <FormLabel>{displayRequestTypeError()}</FormLabel>
            <RadioGroup
              aria-label="request type"
              name="requestTypeRadio"
              value={requestType}
              onChange={handleRequestTypeChange}
              // onBlur={(e) =>
              //   isValidInput(e)
              //     ? setError({ ...error, FirstName: true })
              //     : setError({ ...error, FirstName: false })
              // }
            >
              <FormControlLabel
                value="DCVR"
                name="DCVR"
                control={
                  <Radio
                    aria-checked={requestType === 'DCVR' ? 'true' : 'false'}
                    role="radio"
                    inputProps={{ 'aria-label': 'COVID-19 vaccine record' }}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    style={{ fontFamily: 'Public Sans', fontSize: '1.125rem' }}
                  >
                    <Trans i18nKey="covidCard.covid19Record">
                      I want my COVID-19 Vaccine Record with QR code
                    </Trans>
                  </Typography>
                }
                aria-label="COVID-19 vaccine record Selector"
              />
              <FormControlLabel
                value="DVR"
                name="DVR"
                control={
                  <Radio
                    aria-checked={requestType === 'DVR' ? 'true' : 'false'}
                    role="radio"
                    inputProps={{
                      'aria-label': 'California immunization record',
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    style={{ fontFamily: 'Public Sans', fontSize: '1.125rem' }}
                  >
                    <Trans i18nKey="covidCard.californiaRecord">
                      I want my California Immunization Record
                    </Trans>
                  </Typography>
                }
                aria-label="California Immunization Record Selector"
              />
            </RadioGroup>
            <br />
          </FormControl>
        </div>
        <div className="fieldContainer">
          <div className="line">
            {' '}
            <hr />{' '}
          </div>
          <form id="main" onSubmit={submitForm}>
            <Card
              className="MuiRootCard"
              style={{ border: 'none', boxShadow: 'none' }}
            >
              <CardContent style={{ padding: '16px 12px' }}>
                <br />
                <div style={{ marginBottom: '15px' }}>
                  <Typography>
                    <Trans i18nKey="vaccineForm.subtitle">
                      Required field marked with *
                    </Trans>
                  </Typography>
                </div>

                <Typography style={{ fontSize: '18px', marginBottom: '10px' }}>
                  <Trans i18nKey="vaccineForm.firstName">First Name</Trans>
                </Typography>

                <TextField
                  name="FirstName"
                  label={
                    value.FirstName ? (
                      ''
                    ) : (
                      <Trans i18nKey="vaccineForm.firstNameInput">
                        Enter First Name
                      </Trans>
                    )
                  }
                  variant="outlined"
                  className="col-12"
                  InputLabelProps={{ shrink: false }}
                  inputProps={{
                    maxLength: 30,
                    dir: 'ltr',
                    style: {
                      textAlign: rightOrLeft,
                    },
                  }}
                  required
                  aria-label="First Name"
                  id="FirstName"
                  onClick={(e) => handleClickBorder(e)}
                  onChange={(e) => {
                    isValidInputFName(e)
                      ? setError({ ...error, FirstName: false })
                      : setError({ ...error, FirstName: true });
                    setValue({ ...value, FirstName: e.target.value });
                  }}
                  error={
                    error.FirstName || fNameErrorMessage.type === 'nameErrorMsg'
                  }
                  onBlur={(e) =>
                    isValidInputFName(e)
                      ? setError({ ...error, FirstName: true })
                      : setError({ ...error, FirstName: false })
                  }
                />
                {/* ERROR FOR FIRST NANME */}
                <label htmlFor="partitioned" style={{ color: 'red' }}>
                  {fNameErrorMessage.type === 'nameErrorMsg' ? (
                    <Trans i18nKey={`vaccineForm.${fNameErrorMessage.type}`}>
                      {fNameErrorMessage.message}
                    </Trans>
                  ) : (
                    ''
                  )}
                </label>
                <Typography
                  style={{
                    fontSize: '18px',
                    marginTop: '2rem',
                    marginBottom: '10px',
                  }}
                >
                  <Trans i18nKey="vaccineForm.lastName">Last Name</Trans>
                </Typography>
                <TextField
                  name="LastName"
                  label={
                    value.LastName ? (
                      ''
                    ) : (
                      <Trans i18nKey="vaccineForm.lastNameInput">
                        Enter Last Name
                      </Trans>
                    )
                  }
                  variant="outlined"
                  className="col-12"
                  InputLabelProps={{ shrink: false }}
                  inputProps={{
                    maxLength: 30,
                    dir: 'ltr',
                    style: {
                      textAlign: rightOrLeft,
                    },
                  }}
                  required
                  aria-label="Last name"
                  id="LastName"
                  onChange={(e) => {
                    isValidInputLName(e)
                      ? setError({ ...error, LastName: false })
                      : setError({ ...error, LastName: true });
                    setValue({ ...value, LastName: e.target.value });
                  }}
                  error={
                    error.LastName || lNameErrorMessage.type === 'nameErrorMsg'
                  }
                  onBlur={(e) =>
                    isValidInputLName(e)
                      ? setError({ ...error, LastName: true })
                      : setError({ ...error, LastName: false })
                  }
                />
                {/* ERROR FOR LAST NAME */}
                <label htmlFor="partitioned" style={{ color: 'red' }}>
                  {lNameErrorMessage.type === 'nameErrorMsg' ? (
                    <Trans i18nKey={`vaccineForm.${lNameErrorMessage.type}`}>
                      {lNameErrorMessage.message}
                    </Trans>
                  ) : (
                    ''
                  )}
                </label>
                <Typography
                  style={{
                    fontSize: '18px',
                    marginTop: '2rem',
                    marginBottom: '10px',
                  }}
                >
                  <Trans i18nKey="vaccineForm.dateOfBirth">Date of Birth</Trans>
                </Typography>
                <KeyboardDatePicker
                  name="DateOfBirth"
                  id="dob"
                  inputVariant="outlined"
                  value={selectedBirthDate}
                  onChange={handleDobChange}
                  // onChange={(e) =>
                  //   {handleDobChange;
                  //   setValue({ ...value, LastName: e.target.value })}
                  // }
                  placeholder="MM/DD/YYYY"
                  InputLabelProps={{ shrink: false }}
                  format="MM/DD/YYYY"
                  invalidDateMessage="Required Format: MM/DD/YYYY"
                  label={
                    value.Date ? (
                      ''
                    ) : (
                      <Trans i18nKey="vaccineForm.dateOfBirthInput">
                        (MM/DD/YYYY)
                      </Trans>
                    )
                  }
                  className="col-12"
                  autoOk
                  disableFuture
                  maxDateMessage={
                    <Trans i18nKey="vaccineForm.dateOfBirthErrorMessage">
                      Date should not be after today's date.
                    </Trans>
                  }
                  required
                  error={error.Date || !isDobGood}
                  aria-label="Date of birth"
                  maxDate={new Date('2030-01-01')}
                  onBlur={(e) =>
                    e.target.value.length < 1
                      ? setError({ ...error, Date: true })
                      : setError({ ...error, Date: false })
                  }
                  inputProps={{
                    dir: 'ltr',
                    style: {
                      textAlign: rightOrLeft,
                    },
                  }}
                />
                <FormControl
                  component="fieldset"
                  style={{ marginTop: '50px', marginBottom: '10px' }}
                >
                  <FormLabel
                    component="legend"
                    style={{ fontSize: '18px', fontFamily: 'Arial' }}
                  >
                    <Trans i18nKey="vaccineForm.phoneEmailInfo">
                      Provide your current mobile phone number. If you fail to
                      get a match using your current mobile phone number, try
                      again using your email address.
                    </Trans>
                  </FormLabel>
                  <RadioGroup
                    aria-label="contact type"
                    name="contactTypeRadio"
                    value={contactType}
                    onChange={handleContactTypeChange}
                    row
                  >
                    <FormControlLabel
                      value="Phone"
                      name="Phone"
                      control={
                        <Radio
                          aria-checked={
                            contactType === 'Phone' ? 'true' : 'false'
                          }
                          role="radio"
                          inputProps={{ 'aria-label': 'Phone' }}
                          color="primary"
                        />
                      }
                      label={
                        <Trans i18nKey="vaccineForm.Phone">Mobile Phone</Trans>
                      }
                      aria-label="Mobile Phone Selector"
                    />
                    <FormControlLabel
                      value="Email"
                      name="Email"
                      control={
                        <Radio
                          aria-checked={
                            contactType === 'Email' ? 'true' : 'false'
                          }
                          role="radio"
                          inputProps={{ 'aria-label': 'Email' }}
                          color="primary"
                        />
                      }
                      label={<Trans i18nKey="vaccineForm.Email">Email</Trans>}
                      aria-label="Email Selector"
                    />
                  </RadioGroup>
                </FormControl>
                {contactType === 'Phone' ? (
                  <FormControl className="col-12">
                    <TextField
                      InputProps={{
                        inputComponent: PhoneMask,
                        value: fieldMasks.textmask,
                        dir: 'ltr',
                        inputProps: {
                          style: {
                            textAlign: rightOrLeft,
                          },
                        },
                      }}
                      label={
                        value.Phone ? (
                          ''
                        ) : (
                          <Trans i18nKey="vaccineForm.PhoneInput">
                            Enter Mobile Phone (555) 555-555
                          </Trans>
                        )
                      }
                      placeholder="(555) 555-5555"
                      InputLabelProps={{ shrink: false }}
                      required
                      type="tel"
                      onChange={handlePhoneChange}
                      name="textmask"
                      id="contactPhone"
                      variant="outlined"
                      error={error.Phone_Email}
                      onBlur={(e) => {
                        e.target.value.replace(/[^0-9]/g, '').length < 10
                          ? setError({ ...error, Phone_Email: true })
                          : setError({ ...error, Phone_Email: false });
                      }}
                    />
                  </FormControl>
                ) : (
                  <div>
                    <TextField
                      name="contactType"
                      label={
                        value.Email ? (
                          ''
                        ) : (
                          <Trans i18nKey={`vaccineForm.${contactType}`}>
                            {contactType}
                          </Trans>
                        )
                      }
                      placeholder="example@domain.com"
                      InputLabelProps={{ shrink: false }}
                      variant="outlined"
                      className="col-12"
                      required
                      inputProps={{
                        maxLength: 65,
                        // pattern: "[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+.[a-zA-Z]{2,}$",
                        dir: 'ltr',
                        style: {
                          textAlign: rightOrLeft,
                        },
                      }}
                      type="email"
                      id="contactEmail"
                      onChange={(e) => {
                        e.target.value.length > 0
                          ? setError({ ...error, Phone_Email: false })
                          : setError({ ...error, Phone_Email: true });
                        checkEmail(e.target.value)
                          ? setError({ ...error, Phone_Email: false })
                          : setError({ ...error, Phone_Email: true });
                        setValue({ ...value, Email: e.target.value });
                      }}
                      error={error.Phone_Email}
                      onBlur={(e) => {
                        e.target.value.length < 1
                          ? setError({ ...error, Phone_Email: true })
                          : setError({ ...error, Phone_Email: false });
                        checkEmail(e.target.value)
                          ? setError({ ...error, Phone_Email: false })
                          : setError({ ...error, Phone_Email: true });
                      }}
                    />
                    <label htmlFor="partitioned" style={{ color: 'red' }}>
                      {emailErrorMessage.type === 'emailErrorMsg' &&
                      error.Phone_Email ? (
                        <Trans
                          i18nKey={`vaccineForm.${emailErrorMessage.type}`}
                        >
                          {emailErrorMessage.message}
                        </Trans>
                      ) : (
                        ''
                      )}
                    </label>
                  </div>
                )}
                <FormLabel
                  component="legend"
                  style={{
                    color: error.Pin ? '#f44336' : 'dimgrey',
                    marginTop: '50px',
                    marginBottom: '20px',
                    fontSize: '18px',
                    fontFamily: 'Arial',
                  }}
                >
                  <Trans i18nKey="vaccineForm.pinCode">
                    Create a 4-digit PIN. You'll receive a link to enter the PIN
                    and access your digital vaccine record. *
                  </Trans>
                </FormLabel>
                <div style={{ marginBottom: '20px', marginTop: '40px' }}>
                  <Trans i18nKey="vaccineForm.note">
                    <span
                      style={{
                        background: '#22489C',
                        borderRadius: '5px',
                        color: '#ffffff',
                        padding: '0px 5px 1px 5px',
                        marginLeft: alignment,
                      }}
                    >
                      Note:
                    </span>{' '}
                    Your PIN is needed to securely access your digital vaccine
                    record.
                  </Trans>
                </div>
                <div className="pinContainer">
                  <div id="divInner">
                    <TextField
                      inputProps={{
                        autoComplete: 'off',
                        type: 'tel',
                        name: 'PIN',
                        value: pin,
                        dir: 'ltr',
                        style: {
                          textAlign: rightOrLeft,
                        },
                        onChange: numbersOnly,
                        maxLength: 4,
                        minLength: 4,
                        required: true,
                        onBlur: (e) =>
                          e.target.value.length < 4
                            ? [
                                (e.target.style.background =
                                  'repeating-linear-gradient(90deg, #f44336 0, #f44336 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat'),
                                setError({ ...error, Pin: true }),
                              ]
                            : [
                                (e.target.style.background =
                                  'repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat'),
                                setError({ ...error, Pin: false }),
                              ],
                      }}
                      InputProps={{
                        className: classes.underline,
                      }}
                      id="partitioned"
                    />
                  </div>
                </div>
                <label htmlFor="partitioned" style={{ color: 'red' }}>
                  {errorMessage.type ? (
                    <Trans i18nKey={`vaccineForm.${errorMessage.type}`}>
                      {errorMessage.message}
                    </Trans>
                  ) : (
                    ''
                  )}
                </label>
                <div style={{ display: 'flex' }}>
                  <FormControlLabel
                    htmlFor="submitcheckbox"
                    aria-label="Policy Agree Checkbox"
                    control={
                      <Checkbox
                        style={{ alignSelf: 'start', marginTop: '-5px' }}
                        checked={checked}
                        onChange={handleChange}
                        name="submitChecked"
                        color="primary"
                        id="submitcheckbox"
                      />
                    }
                  />
                  <div>
                    <Trans i18nKey="vaccineForm.checkBoxDescription">
                      Consent Checkbox: By checking this box, you are declaring
                      under penalty of perjury under the laws of the State of
                      California that you are the Patient or Parent/Guardian of
                      the Patient and are therefore authorized to access the
                      Patient’s vaccination record. You are also agreeing to our{' '}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.cdph.ca.gov/Pages/Conditions-of-Use.aspx"
                        style={{
                          color: '#0d6efd',
                          margin: '0',
                          textDecoration: 'underline',
                        }}
                      >
                        Terms and Conditions
                      </a>{' '}
                      and{' '}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.cdph.ca.gov/Pages/privacy-policy.aspx"
                        style={{
                          color: '#0d6efd',
                          margin: '0',
                          textDecoration: 'underline',
                        }}
                      >
                        Privacy Policy
                      </a>{' '}
                      and consenting to receive messages from the State of
                      California regarding your vaccination record.
                    </Trans>
                  </div>
                </div>
                <div>
                  <br />
                  <div style={{ display: 'flex' }}>
                    <FormControlLabel
                      htmlFor="submitcheckbox"
                      aria-label="Policy Agree Second Checkbox"
                      control={
                        <Checkbox
                          style={{ alignSelf: 'start', marginTop: '-5px' }}
                          checked={secondChecked}
                          onChange={handleSecondCheckChange}
                          name="submitSecondChecked"
                          color="primary"
                          id="submitcheckbox"
                        />
                      }
                    />
                    <div>
                      <Trans i18nKey="vaccineForm.checkBoxPrivacyStatement">
                        By checking this box, I acknowledge that I have
                        received this privacy statement, which is provided in
                        compliance with{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CIV&sectionNum=1798.17."
                          style={{
                            color: '#0d6efd',
                            margin: '0',
                            textDecoration: 'underline',
                          }}
                        >
                          California Civil Code - Section 1798.17
                        </a>
                        .
                        <br />
                        <br />
                        The Digital Vaccine Record (DVR) was created by the
                        California Department of Public Health (CDPH) for
                        individuals who wish to receive a digital copy of
                        their California immunization record or a quick
                        response code (QR Code) for use as proof of COVID-19
                        vaccination. Submitting this form is voluntary.
                        <br />
                        <br />
                        The information collected through this form is
                        necessary to correctly identify your record–not
                        supplying the requested information will prevent you
                        from using the DVR. Your personal information is
                        collected pursuant to{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://california.public.law/codes/ca_health_and_safety_code_section_120440"
                          style={{
                            color: '#0d6efd',
                            margin: '0',
                            textDecoration: 'underline',
                          }}
                        >
                          Health and Safety Code section 120440
                        </a>{' '}
                        and will be kept confidential and on file as required
                        by law. Please note that any and all information
                        collected in the DVR may be disclosed to the
                        California State Auditor, the California Office of
                        Health Information Integrity, the California Office of
                        Information Security, or other state and federal
                        agencies as required by law.
                        <br />
                        <br />
                        You have the right to review records CDPH maintains
                        about you. CDPH will, upon request, inform you of the
                        location of your records and the categories of persons
                        who use the information in those records. For more
                        information, contact Chief, Immunization Branch,
                        California Department of Public Health, 850 Marina Bay
                        Pkwy, Bldg. P, Richmond, CA 94804 or by phone (800)
                        578-7889.
                      </Trans>
                    </div>
                  </div>
                </div>
              </CardContent>
              <CardActions style={{ marginBottom: '30px', padding: '8px 0px' }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <button
                    style={{
                      borderRadius: '20px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      backgroundColor:
                          checked &&
                          secondChecked &&
                          !errorMessage.type &&
                          document.getElementById('partitioned').value
                            .length === 4 &&
                          isDobGood &&
                          requestType !== '' &&
                          !checkFormErrors()
                          ? 'rgba(0, 0, 139, 0.85)'
                          : '#bdbdbd',
                      color: 'white',
                      margin: '0px',
                    }}
                    type="submit"
                    size="small"
                    aria-label="submit"
                    disabled={
                          !checked ||
                          !secondChecked ||
                          errorMessage.type ||
                          document.getElementById('partitioned').value
                            .length !== 4 ||
                          !isDobGood ||
                          requestType === '' ||
                          checkFormErrors()
                    }
                  >
                    <Trans i18nKey="vaccineForm.submitbutton">Submit</Trans>
                  </button>
                )}
              </CardActions>
            </Card>
            {responseMessage.message ? (
              <div style={{ color: 'red' }}>
                <Trans i18nKey={`vaccineForm.${responseMessage.type}`}>
                  {responseMessage.message}
                </Trans>
              </div>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default RequestForm;
