import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import CarouselCss from '../styles/Carousel.module.css';
import VaccinationsPane from './VaccinationsPane.js';
import FederalAgencyPane from './FederalAgencyPane.js';

const RTL_LANGUAGE = ['ae'];

export const LandingPageCarousel = (props) => {
  const { i18n } = useTranslation();

  const direction = RTL_LANGUAGE.indexOf(i18n.language) >= 0 ? 'rtl' : 'ltr';

  const [activeIndex, setActiveIndex] = useState(0);
  const [whichWay, setWhichway] = useState(1);

  const { _height, width } = useWindowDimensions();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setArr([
      <VaccinationsPane
        displayLocale={props.displayLocale}
        activeIndex={activeIndex}
        direction={direction}
        index={0}
        key={1}
      />,
      <FederalAgencyPane
        displayLocale={props.displayLocale}
        activeIndex={activeIndex}
        direction={direction}
        index={1}
        key={2}
      />,
    ]);
  }, [activeIndex]);

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const dotOne = () => {
    setActiveIndex(0);
  };

  const dotTwo = () => {
    setActiveIndex(1);
  };

  // const dotThree = () => {
  //   setActiveIndex(2);
  // }

  const [arr, setArr] = useState([
    <VaccinationsPane
      displayLocale={props.displayLocale}
      activeIndex={activeIndex}
      direction={direction}
      index={0}
      key={1}
    />,
    <FederalAgencyPane
      displayLocale={props.displayLocale}
      activeIndex={activeIndex}
      direction={direction}
      index={1}
      key={2}
    />,
  ]);
  const dots = [
    <span
      className={CarouselCss.dot1}
      role="button"
      tabIndex={0}
      style={{ backgroundColor: activeIndex === 0 ? '#22499C' : 'gray' }}
      onClick={dotOne}
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'Enter') {
          e.preventDefault();
          dotOne();
        }
      }}
      aria-label="Select first dot"
    />,
    <span
      className={CarouselCss.dot2}
      role="button"
      tabIndex={0}
      style={{ backgroundColor: activeIndex === 1 ? '#22499C' : 'gray' }}
      onClick={dotTwo}
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'Enter') {
          e.preventDefault();
          dotTwo();
        }
      }}
      aria-label="Select second dot"
    />,
    //   <span className={CarouselCss.dot3} style={{ backgroundColor: activeIndex === 2 ? '#22499C' : 'gray' }} onClick={dotThree} />
  ];

  const _dotStyler = [
    { backgroundColor: 'transparent' },
    { backgroundColor: 'transparent' },
    { backgroundColor: 'transparent' },
  ];

  const [isPaused, setisPaused] = useState(false);

  const [_operatingSystem, setOperatingSystem] = useState('not mobile');

  useEffect(() => {
    const { userAgent } = window.navigator;
    let OS = 'not mobile';

    if (userAgent.indexOf('iPhone') !== -1) OS = 'A';
    if (userAgent.indexOf('Android') !== -1) OS = 'G';

    setOperatingSystem(OS);
  }, []);

  /// //////////////////////////////////////////////////////////////////
  /// // Function For Carousel Arrows///

  const handleAction = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
      setWhichway(1);
    } else if (newIndex >= arr.length) {
      newIndex = arr.length - 1;
      setWhichway(-1);
    }

    setActiveIndex(newIndex);
  };

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 1;
      setWhichway(1);
    } else if (newIndex >= arr.length) {
      newIndex = 0;
      setWhichway(-1);
    }

    setActiveIndex(newIndex);
  };

  /// //////////////////////////////////////////////////////////////
  /// // Automatic Carousel Rotation Using useEffect And setInterval
  useEffect(() => {
    if (!isPaused) {
      const intervalId = setInterval(() => {
        updateIndex(activeIndex + whichWay);
      }, 12000);

      /// //////////////////////////////////////////////////////////////
      /// // Clears Interval When Component Unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  });

  const handlePauseCarousel = () => {
    setisPaused(!isPaused);
  };

  return (
    <div>
      {width > 950 ? (
        <div className={CarouselCss.root}>
          {direction === 'ltr' ? (
            <div
              className={CarouselCss.arrowContainer}
              /* eslint-disable jsx-a11y/alt-text, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-noninteractive-tabindex */
            >
              <img
                className={CarouselCss.arrow}
                style={{ opacity: activeIndex === 0 ? 0 : 1 }}
                src="/imgs/carousel-rightArrowButton.svg"
                aria-label="carousel left arrow"
                onClick={() => handleAction(activeIndex - 1)}
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    e.preventDefault();
                    handleAction(activeIndex - 1);
                  }
                }}
                tabIndex={activeIndex === 0 ? -1 : 0}
              />
              <img
                className={`${CarouselCss.rightArrow}`}
                style={{ opacity: activeIndex === 1 ? 0 : 1 }}
                src="/imgs/carousel-rightArrowButton.svg"
                aria-label="carousel right arrow"
                onClick={() => handleAction(activeIndex + 1)}
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    e.preventDefault();
                    handleAction(activeIndex + 1);
                  }
                }}
                tabIndex={activeIndex === 1 ? -1 : 0}
              />
            </div>
          ) : (
            <div className={CarouselCss.arrowContainer}>
              <img
                className={CarouselCss.arrowRtl}
                style={{ opacity: activeIndex === 0 ? 0 : 1 }}
                src="/imgs/carousel-rightArrowButton.svg"
                aria-label="carousel right arrow"
                onClick={() => handleAction(activeIndex - 1)}
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    e.preventDefault();
                    handleAction(activeIndex - 1);
                  }
                }}
                tabIndex={activeIndex === 0 ? -1 : 0}
              />
              <img
                className={`${CarouselCss.rightArrowRtl}`}
                style={{ opacity: activeIndex === 1 ? 0 : 1 }}
                src="/imgs/carousel-rightArrowButton.svg"
                aria-label="carousel left arrow"
                onClick={() => handleAction(activeIndex + 1)}
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    e.preventDefault();
                    handleAction(activeIndex + 1);
                  }
                }}
                tabIndex={activeIndex === 1 ? -1 : 0}
              />
            </div>
          )}

          <div
            className={CarouselCss.carouselContainer}
            role="status"
            aria-live="off"
            aria-atomic="true"
          >
            {direction === 'ltr' ? (
              <div
                className={CarouselCss.carouselContent}
                style={{ transform: `translate(-${activeIndex * 50}%)` }}
              >
                {arr.map((item, idx) => (
                  <div
                    key={idx}
                    className={`${CarouselCss.componentClassName}`}
                    id="carousel"
                  >
                    {item}
                  </div>
                ))}
              </div>
            ) : (
              <div
                className={CarouselCss.carouselContent}
                style={{ transform: `translate(${activeIndex * 50}%)` }}
              >
                {arr.map((item, idx) => (
                  <div
                    key={idx}
                    className={`${CarouselCss.componentClassName}`}
                    id="carousel"
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}

            <div className={CarouselCss.dotContainer}>
              <img
                aria-label="pause carousel"
                onClick={handlePauseCarousel}
                onKeyDown={(e) => {
                  if (e.key === ' ' || e.key === 'Enter') {
                    e.preventDefault();
                    handlePauseCarousel();
                  }
                }}
                tabIndex={0}
                className={CarouselCss.pauseButtonStyle}
                id="pauseButton"
                src={
                  isPaused
                    ? '/imgs/carousel-playButton.svg'
                    : '/imgs/carousel-pauseButton.svg'
                }
              />
              {dots.map((item, idx) => (
                <div key={idx}>{item}</div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={CarouselCss.mobileRoot}
          role="region"
          aria-live="polite"
        >
          <VaccinationsPane
            displayLocale={props.displayLocale}
            activeIndex={activeIndex}
            direction={direction}
            key={1}
          />
          ,
          <FederalAgencyPane
            displayLocale={props.displayLocale}
            activeIndex={activeIndex}
            direction={direction}
            key={2}
          />
          ,
        </div>
      )}
    </div>
  );
};

export default LandingPageCarousel;
