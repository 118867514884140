import React from 'react';
import VaccineGroupModal from './VaccineGroupModal';
import '../styles/MobileView-style.css';
// import AppController from '../utils/AppController';
// import ReactGA from 'react-ga';
// import RecordDataFooter from './RecordDataFooter';

function displayEvaluatorStatusRecommendedGroup(props) {
  // always show recommended date in the recommended section
  const date = props == null ? null : props.recommendedDate;

  let text = '';

  if (date) {
    text = `Recommended Date: ${date}`;
  }

  return text;
}

function MobileDVRData({
  record,
  getEvaluatorStatusColor,
  displayVaccineGroupName,
  displayEvaluatorStatus,
  settings,
}) {
  return (
    <div className="vaccine-group-container">
      {record.vaccineGroups.map((vaccineGroup) => (
        <div className="vaccine-button-container">
          <VaccineGroupModal
            group={vaccineGroup}
            record={record}
            displayVaccineGroupName={displayVaccineGroupName}
            displayEvaluatorStatus={displayEvaluatorStatus}
            getEvaluatorStatusColor={getEvaluatorStatusColor}
            settings={settings}
          />
        </div>
      ))}

      {/* display vaccine group buttons for recommended vaccine groups */}
      {record &&
        record.evaluatorAvailable &&
        record.emptyGroups &&
        record.emptyGroups.length > 0 && (
          <div>
            <hr />
            <div style={{ marginBottom: '10px' }}>
              <span style={{ fontWeight: 'bold' }}>
                Recommended vaccinations we do not see in your record (future
                recommendations shown in yellow are due within one year):
              </span>
            </div>
            {record.emptyGroups.map((emptyGroup) => (
              <div>
                <div className="vaccine-button-container">
                  <VaccineGroupModal
                    group={emptyGroup}
                    record={record}
                    displayVaccineGroupName={displayVaccineGroupName}
                    displayEvaluatorStatus={
                      displayEvaluatorStatusRecommendedGroup
                    }
                    getEvaluatorStatusColor={getEvaluatorStatusColor}
                    settings={settings}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
    </div>
  );
}

export default MobileDVRData;
