import React, { useState, useEffect } from 'react';
import {
  withAITracking,
  useAppInsightsContext,
} from '@microsoft/applicationinsights-react-js';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { reactPlugin } from '../utils/ApplicationInsightsService';

import LandPageCarousel from './LandingPageCarousel';
import QnAContent from './QAContent';
import BMyTurnContent from './BlueMyTurnContent';

function LandingPage({ htmlDir, settings }) {
  const appInsights = useAppInsightsContext();
  // const { t, i18n } = useTranslation();
  // const currentLanguage = i18n.language;
  // const isArabic = currentLanguage === "ae";

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    pageLoaded();
  }, []);

  const pageLoaded = () => {
    appInsights.trackEvent({ name: 'Landing page loaded' });
  };

  const { _height, width } = useWindowDimensions();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  function pressRequestRecordButton() {
    appInsights.trackEvent({ name: 'Request Record Button Pressed' });
  }

  const { _i18n } = useTranslation();

  return (
    <div>
      {/* Title banner */}
      <div className="LandingRoot">
        <div className="LandingContainer">
          <div
            className={
              htmlDir === 'rtl' ? 'TitleContainer rtl' : 'TitleContainer'
            }
          >
            <div>
              <h1 className="LandingPageH1">
                <Trans i18nKey="landingPage.title">
                  Digital Vaccine Record
                </Trans>
              </h1>
              <h2 className="LandingPageH2">
                <Trans i18nKey="landingPage.subtitle">
                  California's Vaccine Record Site
                </Trans>
              </h2>
              <p className="LandingPagePara">
                <Trans i18nKey="landingPage.instructions">
                  To get a digital copy of your Digital Vaccine Record (DVR),
                  just enter a few details. You can get a link to your COVID-19
                  Vaccine Record with a QR code or your California Immunization
                  Record. Save it on your phone and use it as proof of
                  vaccination wherever you go.
                </Trans>
              </p>
              <Link to="/request" style={{ margin: '0' }} tabIndex={-1}>
                <button
                  type="button"
                  className="bttnStyle"
                  onClick={pressRequestRecordButton}
                >
                  <Trans i18nKey="landingPage.requestRecord">
                    Request Record
                  </Trans>
                </button>
              </Link>
            </div>
          </div>
          {width > 950 ? (
            <div className="HomePageImageContainer">
              <img
                className="HomePageImage"
                aria-label="woman and two children using tablet"
                src="/imgs/Home_page_image.svg"
              />
            </div>
          ) : (
            <div
              className="bkg"
              style={{ backgroundImage: "url('/imgs/Home_page_image.svg')" }}
            />
          )}
        </div>
      </div>
      <div>
        <LandPageCarousel />
      </div>
      <div>
        <QnAContent htmlDir={htmlDir} settings={settings} />
      </div>
      <div>
        <BMyTurnContent />
      </div>
    </div>
  );
}

export default withAITracking(reactPlugin, LandingPage, 'LandingPage');
