import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import BlueApptCss from '../styles/BlueMyTurnContent.module.css';
// import * as fs from 'fs';

export function BlueMyTurnContent(props) {
  const {
    _title,
    _subTitle,
    _subTitle2,
    _paragraph,
    _poolCode,
    _ctaLabel,
    _scrollSteps,
    _buttonStep,
    _instruction,
    _buttonSteps,
    _blueTitle,
    _blueTitleTwo,
    _hero,
  } = props;

  const { _i18n } = useTranslation();
  return (
    <div className={`${BlueApptCss.root}`}>
      <div className={BlueApptCss.blueApptContainer}>
        <div className={BlueApptCss.title}>
          <Trans i18nKey="landingPage.scheduleAppointment">
            Need to schedule a vaccination appointment?
          </Trans>
        </div>
        <div className={BlueApptCss.buttonContainer}>
          <br />
          <a
            href="https://myturn.ca.gov/"
            target="_blank"
            tabIndex={-1}
            rel="noreferrer"
          >
            <button type="button" className={BlueApptCss.buttonStyle}>
              <Trans i18nKey="landingPage.visitMyTurn">Visit My Turn</Trans>
            </button>
          </a>
        </div>
        {/* <div>
              <a className={BlueApptCss.bottomLink} href='https://uat.myturn.ca.gov/manage'>Visit My Turn</a><span className={BlueApptCss.arrowRight} style={{backgroundImage: "url('/landing-static/internal-arrow-white.svg')"}} />
          </div> */}
      </div>
    </div>
  );
}

export default BlueMyTurnContent;
