import { Paper } from '@material-ui/core';
import React from 'react';

import BannerCss from '../styles/BannerContent.module.css';

// eslint-disable-next-line no-unused-vars
function Banner({ settings, setSettings }) {
  const [isAlertClosed, setIsAlertClosed] = React.useState(false);

  const closeBanner = async (_info) => {
    setSettings({
      ...settings,
      MaintenanceBannerClosed: true,
    });
    setIsAlertClosed(true);
  };

  return (
    settings.ShowMaintenanceBanner &&
    (settings.MaintenanceBannerHeader || settings.MaintenanceBannerBody) &&
    !isAlertClosed && (
      <Paper className={BannerCss.root} square elevation={0}>
        <div className={BannerCss.bannerWrapper}>
          <img
            className={BannerCss.alertImage}
            aria-label="banner alert exclamation mark"
            src="/imgs/alert-banner.svg"
          />
          <div className={BannerCss.textContainer}>
            {settings.MaintenanceBannerHeader && (
              <span className={BannerCss.header}>
                {settings.MaintenanceBannerHeader}
              </span>
            )}
            {settings.MaintenanceBannerBody && (
              <span className={BannerCss.body}>
                {settings.MaintenanceBannerBody}
              </span>
            )}
          </div>
          {!isAlertClosed && (
            <button
              className={BannerCss.close}
              type="button"
              onClick={() => closeBanner()}
            >
              <img
                src="/imgs/x-icon-banner.svg"
                aria-label="close alert banner"
                alt=""
              />
            </button>
          )}
        </div>
      </Paper>
    )
  );
}
export default Banner;
