import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const { APPLICATION_INSIGHTS_CONNECTION_STRING } = window.config;

const { APPLICATION_INSIGHTS_DISABLED } = window.config;

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: APPLICATION_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: [
      'fonts.gstatic.com',
      'google-analytics.com',
    ],
    disableTelemetry: APPLICATION_INSIGHTS_DISABLED,
    maxBatchInterval: 1000,
  },
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env) => {
  env.tags = env.tags || [];
  env.tags['ai.cloud.role'] = 'FE-UI';
});

export { reactPlugin, appInsights };
