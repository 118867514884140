import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QRData from './QRData';
import Pin from './Pin';

import RecordData from './RecordData';
import InvalidPage from './InvalidPage';

function QrScreen({
  htmlDir,
  setHtmlDir,
  setShowDVRHeader,
  settings,
  myTurnBannerGroupCodes,
  setMyTurnBannerGroupCodes,
}) {
  const { id, lang, requestType } = useParams();
  const [user, setUser] = useState(null);
  const [qr, setQr] = useState(null);
  const [record, setRecord] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [healthCard, setHealthCard] = useState();
  const [pin, setPin] = useState('');
  const [walletCode, setWalletCode] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [apple, setAppleState] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [google, setGoogleState] = useState(true);
  // const [requestType, setRequestType] = useState(true);
  const [urlInvalid, setUrlInvalid] = useState(false);

  const { userAgent } = navigator;
  const indexOfOS = userAgent.indexOf('OS');
  const versionStr = userAgent.substring(indexOfOS + 2, indexOfOS + 7);
  const versionInt = Number.parseInt(versionStr.substring(0, 3));
  const subversionInt = Number.parseInt(versionStr.substring(4));
  const isPhone = userAgent.match('iPhone'); // iPhones, iPods, and iPads in mobile mode all claim this
  const isiPad = userAgent.match('iPad'); // iPads in mobile mode claim this
  const isVersionGood = isPhone && !isiPad && versionInt >= 15;
  const iOS15later = versionInt >= 15;
  const { CREDENTIALS_API_QR } = window.config;
  const getMobileOperatingSystem = () => {
    const { userAgent } = navigator;
    let OS = 'not mobile';

    if (userAgent.indexOf('iPhone') !== -1) OS = 'A';
    if (userAgent.indexOf('Android') !== -1) OS = 'G';

    return OS;
  };

  useEffect(() => {
    validateUrl();
    if (getMobileOperatingSystem() === 'A') {
      setWalletCode('A');
    } else if (getMobileOperatingSystem() === 'G') {
      setWalletCode('G');
    }
    setMyTurnBannerGroupCodes([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function validateUrl() {
    const credentialData = {
      Id: id,
    };

    let endpoint;

    switch (requestType) {
      case 'DVR':
        endpoint = 'vaccineCredentialDvr';
        break;
      case 'DCVR':
        endpoint = 'vaccineCredential';
        break;
      default:
        return;
    }

    fetch(`${CREDENTIALS_API_QR}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentialData),
    })
      .then((response) => {
        if (!response.ok) {
          setUrlInvalid(true);
        }
      })
      .catch((err) => {});
  }

  if (urlInvalid) {
    return <InvalidPage />;
  }

  if (requestType === 'DCVR' && user !== null && qr !== null) {
    return (
      <div className="bodyContainer qrContainer">
        <QRData
          user={user}
          qr={qr}
          apple={apple}
          google={google}
          isMobile={getMobileOperatingSystem}
          htmlDir={htmlDir}
          subversionInt={subversionInt}
          versionInt={versionInt}
          isVersionGood={isVersionGood}
          iOS15later={iOS15later}
          settings={settings}
        />
      </div>
    );
  }
  if (requestType === 'DVR' && user !== null && record != null) {
    return (
      <div className="bodyContainer qrContainer">
        <RecordData
          apple={apple}
          user={user}
          record={record}
          isMobile={getMobileOperatingSystem}
          isVersionGood={isVersionGood}
          htmlDir={htmlDir}
          setHtmlDir={setHtmlDir}
          lang={lang}
          setShowDVRHeader={setShowDVRHeader}
          settings={settings}
          myTurnBannerGroupCodes={myTurnBannerGroupCodes}
          setMyTurnBannerGroupCodes={setMyTurnBannerGroupCodes}
        />
      </div>
    );
  }
  return (
    <section className="bodyContainer qrContainer">
      <article>
        <Pin
          setPin={setPin}
          setQr={setQr}
          setRecord={setRecord}
          setUser={setUser}
          pin={pin}
          id={id}
          setHealthCard={setHealthCard}
          lang={lang}
          apple={apple}
          google={google}
          walletCode={walletCode}
          isMobile={getMobileOperatingSystem}
          requestType={requestType}
          settings={settings}
        />
      </article>
    </section>
  );
}

export default QrScreen;
