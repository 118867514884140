/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import { Trans, Translation, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import '../styles/HeaderFooter-style.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { appInsights } from '../utils/ApplicationInsightsService';


function Header({ setHtmlDir, htmlDir }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // Language

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  useEffect(() => {
    const normalizeLanguageDisplay = () => {
      const button = document.querySelector('.languageContainer Button');

      if (button.innerText === 'EN-US') {
        const strongElement = button.querySelector('strong');
        if (strongElement && strongElement.innerText === 'EN-US') {
          strongElement.innerText = strongElement.innerText.replace(
            'EN-US',
            'en',
          );
        }
      }
    };
    requestAnimationFrame(() => {
      normalizeLanguageDisplay();
    });
  }, []);

  const changeLanguage = (language) => {
    const baseLanguage = language.split('-')[0];
    const htmlTag = document.getElementsByTagName('html');
    if (baseLanguage === 'ae') {
      htmlTag[0].dir = 'rtl';
      // setHtmlDir('rtl')
    } else {
      htmlTag[0].dir = 'ltr';
      // setHtmlDir('ltr')
    }
    setHtmlDir(document.documentElement.dir);
    i18n.changeLanguage(baseLanguage);
    setLanguage(baseLanguage);

    window.localStorage.setItem('language', baseLanguage);
    window.localStorage.setItem('doc dir', document.documentElement.dir);
  };

  const [expand, setExpand] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = useState(
    window.navigator.userLanguage || navigator.language.length > 2
      ? navigator.language.substring(0, 2).toLowerCase()
      : navigator.language,
  );
  const [isQAHover, setIsQAHover] = useState(false);
  const [isMyTurnHover, setIsMyTurnHover] = useState(false);
  const [mobileBurgerSelected, setMobileBurgerSelected] = useState(false);
  const [path, setPath] = useState('/');
  const firstDropdownItemRef = useRef(null);
  const dropdownTriggerRef = useRef(null);
  const lastDropdownItemRef = useRef(null);
  const myTurnFirstDropdownItemRef = useRef(null);
  const myTurnDropdownTriggerRef = useRef(null);
  const myTurnLastDropdownItemRef = useRef(null);

  const styles = {
    homeLink: {
      fontWeight: path === '/' ? 'bold' : 'inherit',
    },

    qaLink: {
      fontWeight: path === '/faq' ? 'bold' : 'inherit',
    },
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setExpand(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpand(false);
  };

  const handleQAMenuClick = () => {
    setIsQAHover(!isQAHover);
  };

  const handleQAHoverEnter = () => {
    setIsQAHover(true);
  };

  const handleQAHoverLeave = () => {
    setIsQAHover(false);
  };

  const handleMyTurnMenuClick = () => {
    setIsMyTurnHover(!isMyTurnHover);
  };

  const handleMyTurnHoverEnter = () => {
    setIsMyTurnHover(true);
  };

  const handleMyTurnHoverLeave = () => {
    setIsMyTurnHover(false);
  };

  const handleKeyboardLanguage = (e, lang) => {
    const code = e.keyCode || e.charCode;
    if (code === 13 || code === 32) {
      if (code === 32) {
        e.preventDefault();
      }
      changeLanguage(lang);
    }
  };

  const handleBurgerMenuClick = () => {
    setMobileBurgerSelected(!mobileBurgerSelected);
    setIsMyTurnHover(false);

  };

  const HandleBurgerMenuKeyDown = () => {
    setMobileBurgerSelected(true);
  };

  const HandleBurgerMenuKeyDownClose = (e) => {
    if (e.key !== 'Tab') {
      setMobileBurgerSelected(false);
      setIsMyTurnHover(false);
    }
  };

  function qaLinkPressed() {
    appInsights.trackEvent({ name: 'Header Q&A Pressed' });
  }

  function myTurnLinkPressed() {
    appInsights.trackEvent({ name: 'Header My Turn Link Pressed' });
  }

  return (
    <nav className="navigationRoot">

        <Link to='/'>
          <img
            style={{ alignSelf: "center" }}
            alt={"CDPH-DVR Logo"}
            src="/imgs/cdph-dvr-logo.svg"
            className="cdphDvrImg"
          />
        </Link>
        <div className="linkContainer">
          <Link to='/' className="linkText" style={styles.homeLink} title={t('landingPage.home')}>
            <Trans i18nKey="landingPage.home">
              Home
            </Trans>
          </Link>
          <button
              eventLabel="qa_page_link"
              className='linkText'
              title={t('generalFaq.title')}
              onMouseEnter={handleQAHoverEnter} 
              onMouseLeave={handleQAHoverLeave}
              role="button"
              aria-haspopup="true"
              aria-expanded={isQAHover}
              ref={dropdownTriggerRef}
              onClick={() => {
                    if (isQAHover) {
                      handleQAHoverLeave();
                    } else {
                        handleQAHoverEnter();
                        setTimeout(() => {
                          if (firstDropdownItemRef.current) {
                              firstDropdownItemRef.current.focus();
                          }
                      }, 0);
                    }
              }}
              onKeyDown={(e) => {                
                  if (e.key === 'Escape'){
                    e.preventDefault();
                    handleQAHoverLeave();
                  }
                  
              }}
              style={styles.qaLink}
              tabIndex={0}
          >
              {/* <Trans i18nKey="footer.accessibility">Submit a request to the Virtual Assistant</Trans> */}
            <Trans i18nKey="generalFaq.title">
              DVR Q&A
            </Trans>
            {!isQAHover ? <span className='downArrow' id='downArrow'></span> : <span className='arrow' id='upArrow'></span>}
            {isQAHover && <div className='navItemDropdownMenu' role="menu" aria-label="FAQ Options">  
              {[ { title: 'generalFaq.general', url: '/faq?tab=0' },
                { title: 'covidFaq.covid19', url: '/faq?tab=1' },
                {
                  title: 'healthcareFaq.healthcareProviders',
                  url: '/faq?tab=2',
                },
              ].map((tab, index) => (
                <a
                  key={index}
                  className="navItemDropdownOption linkSelect"
                  href={tab.url}
                  role="menuitem"
                  ref={
                    index === 0
                      ? firstDropdownItemRef
                      : index === 2
                        ? lastDropdownItemRef
                        : null
                  }
                  onClick={handleQAMenuClick}
                  onKeyDown={(e) => {
                    if (e.key === 'ArrowUp') {
                      e.preventDefault();
                      if (index <= 0) {
                        lastDropdownItemRef.current.focus();
                      } else {
                        e.target.previousElementSibling.focus();
                      }
                    } else if (e.key === 'ArrowDown') {
                      e.preventDefault();
                      if (index < 2) {
                        e.target.nextElementSibling.focus();
                      }
                      if (index === 2) {
                        firstDropdownItemRef.current.focus();
                      }
                    } else if (
                      e.key === 'Escape' ||
                      (e.key === 'Tab' && e.shiftKey) ||
                      e.key === 'Tab'
                    ) {
                      handleQAHoverLeave();
                      dropdownTriggerRef.current.focus();
                      e.preventDefault();
                    } else if (e.key === 'Enter') {
                      e.preventDefault();
                      e.target.click();
                    }
                  }}
                  tabIndex={0}
                  onMouseEnter={handleQAHoverEnter}
                  onMouseLeave={handleQAHoverLeave}
                  aria-label={tab.title}
                >
                  <Trans i18nKey={tab.title} />
                </a>
            ))}

          </div>}
          </button>

          <button
              eventLabel="myTurn_dropdown_link"
              className='linkText'
              title={t('landingPage.myTurn')}
              onMouseEnter={handleMyTurnHoverEnter} 
              onMouseLeave={handleMyTurnHoverLeave}
              role="button"
              aria-haspopup="true"
              aria-expanded={isMyTurnHover}
              ref={myTurnDropdownTriggerRef}
              onClick={() => {
                    if (isMyTurnHover) {
                      handleMyTurnHoverLeave();
                    } else {
                      handleMyTurnHoverEnter();
                        setTimeout(() => {
                          if (myTurnFirstDropdownItemRef.current) {
                              myTurnFirstDropdownItemRef.current.focus();
                          }
                      }, 0);
                    }
              }}
              onKeyDown={(e) => {                
                  if (e.key === 'Escape'){
                    e.preventDefault();
                    handleMyTurnHoverLeave();
                  }
                  
              }}
              style={styles.qaLink}
              tabIndex={0}
          >
              {/* <Trans i18nKey="footer.accessibility">Submit a request to the Virtual Assistant</Trans> */}
            <Trans i18nKey="landingPage.myTurn">
              My Turn
            </Trans>
            {!isMyTurnHover ? <span className='downArrow' id='downArrow'></span> : <span className='arrow' id='upArrow'></span>}
            {isMyTurnHover && <div className='navItemDropdownMenu' role="menu" aria-label="My Turn Options">  
              {[ { title: 'landingPage.myTurnHome', url: 'https://myturn.ca.gov/' },
                { title: 'landingPage.myTurnAppointments', url: 'https://myturn.ca.gov/screening' },
                {
                  title: 'landingPage.myTurnVaccineLocator',
                  url: 'https://myturn.ca.gov/vaccinelocator.html',
                },
              ].map((tab, index) => (
                <a
                  key={index}
                  target='_blank'
                  className="navItemDropdownOption linkSelect"
                  href={tab.url}
                  role="menuitem"
                  ref={
                    index === 0
                      ? myTurnFirstDropdownItemRef
                      : index === 2
                        ? myTurnLastDropdownItemRef
                        : null
                  }
                  onClick={handleMyTurnMenuClick}
                  onKeyDown={(e) => {
                    if (e.key === 'ArrowUp') {
                      e.preventDefault();
                      if (index <= 0) {
                        myTurnLastDropdownItemRef.current.focus();
                      } else {
                        e.target.previousElementSibling.focus();
                      }
                    } else if (e.key === 'ArrowDown') {
                      e.preventDefault();
                      if (index < 2) {
                        e.target.nextElementSibling.focus();
                      }
                      if (index === 2) {
                        myTurnFirstDropdownItemRef.current.focus();
                      }
                    } else if (
                      e.key === 'Escape' ||
                      (e.key === 'Tab' && e.shiftKey) ||
                      e.key === 'Tab'
                    ) {
                      handleMyTurnHoverLeave();
                      myTurnDropdownTriggerRef.current.focus();
                      e.preventDefault();
                    } else if (e.key === 'Enter') {
                      e.preventDefault();
                      e.target.click();
                    }
                  }}
                  tabIndex={0}
                  onMouseEnter={handleMyTurnHoverEnter}
                  onMouseLeave={handleMyTurnHoverLeave}
                  aria-label={tab.title}
                >
                  <Trans i18nKey={tab.title} />
                </a>
            ))}

          </div>}
          </button>
        </div>
        <div className="rightItemsNavContainer">
          <div className="languageContainer">
            <Button aria-controls="simple-menu" aria-haspopup="true" aria-label="Change Language Dropdown" onClick={handleClick} style={{ fontWeight: '400', padding: '2px 0px 0px 0px' }}>
                <strong>{language}</strong> {expand === false ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem aria-label="english" onClick={() => { handleClose(); changeLanguage('en') }} style={{ textDecoration: 'underline' }}>English</MenuItem>
              <MenuItem aria-label="spanish" onClick={() => { handleClose(); changeLanguage('es') }} style={{ textDecoration: 'underline' }}>Español</MenuItem>
              <MenuItem aria-label="simplified chinese" onClick={() => { handleClose(); changeLanguage('cn') }} style={{ textDecoration: 'underline' }}>简体字</MenuItem>
              <MenuItem aria-label="tagalog" onClick={() => { handleClose(); changeLanguage('ph') }} style={{ textDecoration: 'underline' }}>Tagalog</MenuItem>
              <MenuItem aria-label="vietnamese" onClick={() => { handleClose(); changeLanguage('vi') }} style={{ textDecoration: 'underline' }}>Tiếng Việt (Vietnamese)</MenuItem>
              <MenuItem aria-label="korean" onClick={() => { handleClose(); changeLanguage('kr') }} style={{ textDecoration: 'underline' }}>한국어 (Korean)</MenuItem>
              <MenuItem aria-label="traditional chinese" onClick={() => { handleClose(); changeLanguage('tw') }} style={{ textDecoration: 'underline' }}>繁體字 (Traditional)</MenuItem>
              <MenuItem aria-label="arabic" id={'arabic-menu-item'} onClick={() => { handleClose(); changeLanguage('ae') }} style={{ textDecoration: 'underline' }}>العربية (Arabic)</MenuItem>
            </Menu>
          </div>
          <img src="/imgs/navbar-hamburger-icon.svg" className="hamburgerIcon" tabIndex={0} onKeyDown={HandleBurgerMenuKeyDown} onClick={handleBurgerMenuClick}/>
        </div>

      {mobileBurgerSelected && (
        <div className="navigationContainerMobile">
          <div className="navigationButtonsMobile" style={isMyTurnHover ? {justifyContent: 'space-between'} : {justifyContent: 'end'}}>
            {isMyTurnHover && <div>
              <img
                className="backArrowIcon"
                src="/imgs/leftArrow.svg"
                tabIndex={0}
                onClick={handleMyTurnMenuClick}
                onKeyDown={handleMyTurnMenuClick}
                style={(i18n.resolvedLanguage?.toLowerCase() === "ae") ? {transform: 'rotateY(180deg)'} : null}
              />
              <Trans i18nKey="landingPage.myTurn">My Turn</Trans>
            </div>}
            <img
              className="xIcon"
              src="/imgs/xIcon.svg"
              tabIndex={0}
              onKeyDown={HandleBurgerMenuKeyDownClose}
              onClick={handleBurgerMenuClick}
            />
          </div>
          {!isMyTurnHover && <div className="linkContainerMobile">
            <Link
              to="/"
              className="linkTextMobile"
              style={styles.homeLink}
              tabIndex={0}
            >
              <Trans i18nKey="landingPage.home">Home</Trans>
            </Link>
            <Link
              eventLabel="qa_page_link"
              to="/faq"
              className="linkTextMobile"
              tabIndex={0}
              onClick={qaLinkPressed}
              onKeyDown={qaLinkPressed}
              style={styles.qaLink}
            >
              {/* <Trans i18nKey="footer.accessibility">Submit a request to the Virtual Assistant</Trans> */}
              <Trans i18nKey="generalFaq.title">DVR Q&A</Trans>
            </Link>
            <Link
              eventLabel="myturn_menu"
              className="linkTextMobile"
              onClick={handleMyTurnMenuClick}
              tabIndex={0}
            >
              <Trans i18nKey="landingPage.myTurn">My Turn</Trans>
              <img
                className="linkArrowMobile"
                src="/imgs/rightArrow.svg"
                style={(i18n.resolvedLanguage?.toLowerCase() === "ae") ? {transform: 'rotateY(180deg)'} : null}
              />
            </Link>
          </div>}
          {isMyTurnHover && <div className="myTurnMenuMobile">
            <ReactGA.OutboundLink
              eventLabel="myturn_page_home_link"
              to="https://myturn.ca.gov/"
              target="_blank"
              className="linkTextMobile"
              onClick={myTurnLinkPressed}
              tabIndex={0}
              style={{fontWeight: '700'}}
            >
              <Trans i18nKey="landingPage.myTurnHome">Home</Trans>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink
              eventLabel="myturn_page_appointments_link"
              to="https://myturn.ca.gov/screening"
              target="_blank"
              className="linkTextMobile"
              onClick={myTurnLinkPressed}
              tabIndex={0}
              style={{fontWeight: '700'}}
            >
              <Trans i18nKey="landingPage.myTurnAppointments">Appointments</Trans>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink
              eventLabel="myturn_page_locator_link"
              to="https://myturn.ca.gov/vaccinelocator.html"
              target="_blank"
              className="linkTextMobile"
              onClick={myTurnLinkPressed}
              tabIndex={0}
              style={{fontWeight: '700'}}
            >
              <Trans i18nKey="landingPage.myTurnVaccineLocator">Vaccine Locator</Trans>
            </ReactGA.OutboundLink>
          </div>}
        </div>
      )}
    </nav>
  );
}

export default Header;
