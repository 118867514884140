import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';

function VaccineGroupTable({ group, record }) {
  const columns = [
    {
      id: 'vaccineName',
      Cell: ({ value }) => <b>{value}</b>,
      align: 'center',
      width: '27.5%',
    },
    {
      id: 'series',
      align: 'center',
      width: '15%',
    },
    {
      id: 'dateGiven',
      align: 'center',
      width: '15%',
    },
    {
      id: 'ageGiven',
      align: 'center',
      width: '15%',
    },
    {
      id: 'clinicName',
      align: 'center',
      width: '27.5%',
    },
  ];

  // go through the doses in the vaccine group and add each as a row
  function generateRows(group) {
    group = group.group;
    const rows = [];

    // eslint-disable-next-line array-callback-return
    group.existingDoses.map((dose, _index) => {
      rows.push(dose);
    });

    return rows;
  }

  function isNA(columnId, value) {
    return columnId === 'series' && value === 'N/A';
  }

  const useStylesBootstrap = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: 'white',
      color: theme.palette.common.black,
      fontWeight: 'bold',
      border: '1px solid black',
    },
  }));

  const tooltipClass = useStylesBootstrap();

  function invalidTableCellEvaluatorAvailable(row) {
    return (
      <span>
        Invalid
        {row.invalidSuperscriptLabel && (
          <sup>
            1{row.invalidSuperscriptLabel}
            <Tooltip
              title={row.invalidReasonText}
              classes={tooltipClass}
              placement="right-end"
              style={{ width: 17, height: 17 }}
            >
              <IconButton size="small" icon>
                <SvgIcon fontSize="inherit">
                  {/* credit: info icon from https://fonts.google.com/icons?selected=Material%20Symbols%20Outlined%3Ainfo%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4024 */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 -960 960 960"
                  >
                    <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                  </svg>
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </sup>
        )}
      </span>
    );
  }

  function createTable(rows) {
    return (
      <Table
        style={{ width: '100%', borderStyle: 'solid', tableLayout: 'fixed' }}
      >
        <TableHead style={{ backgroundColor: '#E1E0E3', padding: 10 }}>
          <TableCell
            key={columns[0].id}
            align={columns[0].align}
            width={columns[0].width}
            style={{
              border: 'solid',
              borderColor: 'grey',
              padding: 10,
              fontWeight: 'bold',
            }}
          >
            Vaccine
          </TableCell>

          <TableCell
            key={columns[1].id}
            align={columns[1].align}
            width={columns[1].width}
            style={{
              border: 'solid',
              borderColor: 'grey',
              padding: 10,
              fontWeight: 'bold',
            }}
          >
            {record.evaluatorAvailable ? 'Dose' : 'Series'}
          </TableCell>

          <TableCell
            key={columns[2].id}
            align={columns[2].align}
            width={columns[2].width}
            style={{
              border: 'solid',
              borderColor: 'grey',
              padding: 10,
              fontWeight: 'bold',
            }}
          >
            Date Given
          </TableCell>
          <TableCell
            key={columns[3].id}
            align={columns[3].align}
            width={columns[3].width}
            style={{
              border: 'solid',
              borderColor: 'grey',
              padding: 10,
              fontWeight: 'bold',
            }}
          >
            Age Given
          </TableCell>
          <TableCell
            key={columns[4].id}
            align={columns[4].align}
            width={columns[4].width}
            style={{
              border: 'solid',
              borderColor: 'grey',
              padding: 10,
              fontWeight: 'bold',
            }}
          >
            Clinic that Administered or Transcribed
          </TableCell>
        </TableHead>

        <TableBody>
          {rows.map((row) => (
            <TableRow tabIndex={-1} key={row.code}>
              {columns.map((column) => {
                const value = row[column.id];
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    width={column.width}
                    style={{
                      border: 'solid',
                      borderColor: 'grey',
                      padding: 10,
                      wordBreak: 'break-word',
                    }}
                  >
                    {column.format && typeof value === 'number' ? (
                      column.format(value)
                    ) : isNA(column.id, value) ? (
                      <span>
                        {value}
                        <sup>2</sup>
                      </span>
                    ) : column.id === 'series' && !row.isValid ? (
                      `${value} - Invalid*`
                    ) : value === 'Invalid' && record.evaluatorAvailable ? (
                      invalidTableCellEvaluatorAvailable(row)
                    ) : (
                      value
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return <div>{createTable(generateRows({ group }))}</div>;
}

export default VaccineGroupTable;
