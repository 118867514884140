/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { Trans, useTranslation } from 'react-i18next';

import {
  withAITracking,
  useAppInsightsContext,
} from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../utils/ApplicationInsightsService';

const Pin = ({
  pin,
  setPin,
  setQr,
  setRecord,
  setUser,
  id,
  setHealthCard,
  lang,
  walletCode,
  requestType,
  settings,
}) => {
  const [loading, setLoading] = useState(false);
  const [lockedOut, setLockedOut] = useState(false);
  const { i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState({});

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    changeLanguage(lang);
  }, [walletCode]);

  useEffect(() => {
    pageLoaded(requestType);
  }, []);

  const pageLoaded = (requestType) => {
    if (requestType === 'DVR') {
      appInsights.trackEvent({ name: 'DVR Pin Page Loaded' });
    } else if (requestType === 'DCVR') {
      appInsights.trackEvent({ name: 'DCVR Pin Page Loaded' });
    } else {
      appInsights.trackEvent({ name: 'Pin Page Without Request Type Loaded' });
    }
  };

  const [error, setError] = useState({
    FirstName: false,
    LastName: false,
    Phone: false,
    Email: false,
    Pin: false,
    Date: false,
  });
  const { CREDENTIALS_API_QR } = window.config;

  const getBlobUrl = (data, type) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type });

    return URL.createObjectURL(blob);
  };

  const submitPin = (e) => {
    // All vaccines
    if (requestType === 'DVR') {
      const credentialData =
        walletCode !== null
          ? {
              Id: id,
              Pin: pin,
              WalletCode: walletCode,
            }
          : {
              Id: id,
              Pin: pin,
            };

      e.preventDefault();
      let status = 0;
      setLoading(true);

      fetch(`${CREDENTIALS_API_QR}/vaccineCredentialDvr`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentialData),
      })
        .then((res) => {
          if (res.status === 404) {
            setErrorMessage({
              type: 'pinErrorMsg3',
              message:
                "Error: The PIN entered is incorrect or you've passed the 24-hour limit to retrieve your DVR. Please try again or start over at the DVR site.",
            });
            setLoading(false);
          } else if (res.status === 429) {
            setErrorMessage({
              type: 'pinErrorMsg4',
              message: 'Please try your request again in 1 minute.',
            });
            setLoading(false);
          } else if (
            res.status !== 422 &&
            res.status !== 423 &&
            res.status !== 200
          ) {
            setLoading(false);
            setErrorMessage({
              type: 'pinErrorMsg6',
              message: 'Could not complete the request, please retry later.',
            });
          }

          status = res.status;
          return res.json();
        })
        .then((data) => {
          if (status === 200) {
            setUser(data);
            setRecord(data);
            // setHealthCard(
            //   getBlobUrl(data.fileContentSmartCard, data.mimeTypeSmartCard)
            // );

            setLoading(false);
          } else if (status === 422) {
            if (data.invalidRequestType) {
              setErrorMessage({
                type: 'pinErrorMsg6',
                message: 'Could not complete your request, please try again.',
              });
              setLoading(false);
            } else {
              setErrorMessage({
                type: 'pinErrorMsg5',
                message: `We are currently experiencing difficulties accessing your record. Please start over at the DVR site in 24 hours. If you still experience issues, please submit a remediation request through our ${(
                  <a
                    href={settings.VATLink}
                    style={{
                      display: 'inline',
                      color: '#0D6EFD',
                      margin: '0 0',
                      textDecoration: 'underline',
                    }}
                  >
                    Virtual Assitant
                  </a>
                )}.`,
              });
              setLoading(false);
            }
          } else if (status === 423) {
            const timeRemaining = data.time;
            const attemptsRemaining = data.attempts;
            setLoading(false);
            switch (attemptsRemaining) {
              case 0:
                setErrorMessage({
                  type: 'lockoutStartOver',
                  message:
                    'Error: The PIN entered is incorrect or you’ve passed the 24-hour limit to retrieve your DVR. You have reached the maximum attempts to input your PIN. Please start over at the DVR site.',
                  timeRemaining,
                });
                setLockedOut(true);
                break;
              case 1:
                // in english, if there is more than one minute remaining, set the plural version of the error. minutes instead of minute.
                if (i18n.language === 'en' && timeRemaining > 1) {
                  setErrorMessage({
                    type: 'lockoutLastAttemptPlural',
                    message:
                      'Error: The PIN entered is incorrect or you’ve passed the 24-hour limit to retrieve your DVR. Try again in 5 minutes for your final attempt, or start over at the DVR site.',
                    timeRemaining,
                  });
                } else {
                  setErrorMessage({
                    type: 'lockoutLastAttempt',
                    message:
                      'Error: The PIN entered is incorrect or you’ve passed the 24-hour limit to retrieve your DVR. Try again in 5 minutes for your final attempt, or start over at the DVR site.',
                    timeRemaining,
                  });
                }
                break;
              default:
                setErrorMessage({
                  type: 'lockout',
                  message:
                    'Error: The PIN entered is incorrect or you’ve passed the 24-hour limit to retrieve your DVR. Please try again in 1 minute or start over at the DVR site.',
                  timeRemaining,
                });
                break;
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage({
            type: 'pinErrorMsg6',
            message: 'Could not connect right now, please retry later.',
          });
          appInsights.trackException(error, {
            name: 'DVR Pin Page: No response',
          });
        });
    }

    // Covid only
    else if (requestType === 'DCVR') {
      const credentialData =
        walletCode !== null
          ? {
              Id: id,
              Pin: pin,
              WalletCode: walletCode,
            }
          : {
              Id: id,
              Pin: pin,
            };

      e.preventDefault();
      let status = 0;
      setLoading(true);

      fetch(`${CREDENTIALS_API_QR}/vaccineCredential`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentialData),
      })
        .then((res) => {
          if (res.status === 404) {
            setErrorMessage({
              type: 'pinErrorMsg3',
              message:
                "Error: The PIN entered is incorrect or you've passed the 24-hour limit to retrieve your DVR. Please try again or start over at the DVR site.",
            });
            setLoading(false);
          } else if (res.status === 429) {
            setErrorMessage({
              type: 'pinErrorMsg4',
              message: 'Please try your request again in 1 minute.',
            });
            setLoading(false);
          } else if (
            res.status !== 422 &&
            res.status !== 423 &&
            res.status !== 200
          ) {
            setLoading(false);
            setErrorMessage({
              type: 'pinErrorMsg6',
              message: 'Could not complete the request, please retry later.',
            });
          }
          status = res.status;
          return res.json();
        })
        .then((data) => {
          if (status === 200) {
            setUser(data);
            setQr(getBlobUrl(data.fileContentQr, data.mimeTypeQr));
            setHealthCard(
              getBlobUrl(data.fileContentSmartCard, data.mimeTypeSmartCard),
            );
            setLoading(false);
          } else if (status === 422) {
            if (data.invalidRequestType) {
              setErrorMessage({
                type: 'pinErrorMsg6',
                message: 'Could not complete your request, please try again.',
              });
              setLoading(false);
            } else {
              setErrorMessage({
                type: 'pinErrorMsg5',
                message: `We are currently experiencing difficulties accessing your record. Please start over at the DVR site in 24 hours. If you still experience issues, please submit a remediation request through our ${(
                  <a
                    href={settings.VATLink}
                    style={{
                      display: 'inline',
                      color: '#0D6EFD',
                      margin: '0 0',
                      textDecoration: 'underline',
                    }}
                  >
                    Virtual Assitant
                  </a>
                )}.`,
              });
              setLoading(false);
            }
          } else if (status === 423) {
            const timeRemaining = data.time;
            const attemptsRemaining = data.attempts;
            setLoading(false);
            switch (attemptsRemaining) {
              case 0:
                setErrorMessage({
                  type: 'lockoutStartOver',
                  message:
                    'Error: The PIN entered is incorrect or you’ve passed the 24-hour limit to retrieve your DVR. You have reached the maximum attempts to input your PIN. Please start over at the DVR site.',
                  timeRemaining,
                });
                setLockedOut(true);
                break;
              case 1:
                // in english, if there is more than one minute remaining, set the plural version of the error. minutes instead of minute.
                if (i18n.language === 'en' && timeRemaining > 1) {
                  setErrorMessage({
                    type: 'lockoutLastAttemptPlural',
                    message:
                      'Error: The PIN entered is incorrect or you’ve passed the 24-hour limit to retrieve your DVR. Try again in 5 minutes for your final attempt, or start over at the DVR site.',
                    timeRemaining,
                  });
                } else {
                  setErrorMessage({
                    type: 'lockoutLastAttempt',
                    message:
                      'Error: The PIN entered is incorrect or you’ve passed the 24-hour limit to retrieve your DVR. Try again in 5 minutes for your final attempt, or start over at the DVR site.',
                    timeRemaining,
                  });
                }
                break;
              default:
                setErrorMessage({
                  type: 'lockout',
                  message:
                    'Error: The PIN entered is incorrect or you’ve passed the 24-hour limit to retrieve your DVR. Please try again in 1 minute or start over at the DVR site.',
                  timeRemaining,
                });
                break;
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage({
            type: 'pinErrorMsg6',
            message: 'Could not connect right now, please retry later.',
          });
          appInsights.trackException(error, {
            name: 'DCVR Pin Page: No response',
          });
        });
    }
  };

  const numbersOnly = (e) => {
    if (e.target.value.length === 4) {
      e.target.style.background =
        'repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat';
      setError({ ...error, Pin: false });
    }
    const numsOnly = e.target.value.replace(/[^0-9]/g, '');
    setPin(numsOnly);
  };

  const useStyles = makeStyles({
    underline: {
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  });
  const classes = useStyles();
  return (
    <div className="pin-container" style={{ margin: '30px' }}>
      <form onSubmit={submitPin} id="main">
        <Card
          className="MuiRootCard"
          style={{ border: 'none', boxShadow: 'none' }}
          /* eslint-disable jsx-a11y/label-has-associated-control */
        >
          <label htmlFor="partitioned" style={{ display: 'block' }}>
            <h1 className="pinTitle">
              <Trans i18nKey="qrpage.pinCode">PIN code:</Trans>
            </h1>
          </label>
          <div>
            <p className="pinBody">
              <Trans i18nKey="vaccineForm.enterPin">
                Please enter the PIN code you created to request access to your
                Digital Vaccine Record (DVR).
              </Trans>
            </p>
          </div>
          {!lockedOut ? (
            <TextField
              inputProps={{
                autoComplete: 'off',
                type: 'tel',
                name: 'PIN',
                value: pin,
                onChange: numbersOnly,
                maxLength: 4,
                minLength: 4,
                required: true,
                onBlur: (e) =>
                  e.target.value.length < 4
                    ? [
                        (e.target.style.background =
                          'repeating-linear-gradient(90deg, #f44336 0, #f44336 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat'),
                        setError({ ...error, Pin: true }),
                      ]
                    : [
                        (e.target.style.background =
                          'repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat'),
                        setError({ ...error, Pin: false }),
                      ],
              }}
              InputProps={{
                className: classes.underline,
              }}
              id="partitioned"
            />
          ) : (
            <div id="lockout-blocks">
              <TextField
                id="partitioned"
                disabled
                InputProps={{
                  className: classes.underline,
                }}
              />
            </div>
          )}

          <CardActions style={{ padding: '8px 0px' }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <button
                style={{
                  borderRadius: '50px',
                  padding: '0px 0px',
                  backgroundColor: pin && !lockedOut ? '#22489C' : 'gray',
                  color: 'white',
                  height: '50px',
                  margin: '0px',
                  marginTop: '30px',
                  width: '123px',
                }}
                type="submit"
                size="small"
                disabled={!pin || lockedOut}
              >
                <Trans i18nKey="vaccineForm.submitbutton">Submit</Trans>
              </button>
            )}
          </CardActions>
        </Card>
        <div style={{ color: 'red' }}>
          {errorMessage.message ? (
            <Trans
              i18nKey={`vaccineForm.${errorMessage.type}`}
              count={errorMessage.timeRemaining}
            >
              <a
                href="https://myvaccinerecord.cdph.ca.gov/"
                style={{
                  display: 'inline',
                  color: '#0D6EFD',
                  margin: '0 0',
                  textDecoration: 'underline',
                }}
              >
                {errorMessage.message}
              </a>
              <a
                href={settings.VATLink}
                style={{
                  display: 'inline',
                  color: '#0D6EFD',
                  margin: '0 0',
                  textDecoration: 'underline',
                }}
              >
                {errorMessage.message}
              </a>
              {errorMessage.message}
            </Trans>
          ) : (
            ''
          )}
        </div>
      </form>
    </div>
  );
};

export default withAITracking(reactPlugin, Pin, 'Pin');
