import { Paper } from '@material-ui/core';
import React, { useEffect } from 'react';

import BannerCss from '../styles/BannerContent.module.css';

// eslint-disable-next-line no-unused-vars
function MyTurnBanner({ settings, myTurnBannerGroupCodes }) {
  const [isAlertClosed, setIsAlertClosed] = React.useState(false);

  useEffect(() => {}, [myTurnBannerGroupCodes]);

  const closeBanner = async (_info) => {
    setIsAlertClosed(true);
  };

  const getMyTurnBannerMessage = () => {
    let bannerMessage = '';

    if (!settings.MyTurnBannerVaccineGroups) {
      return bannerMessage;
    }

    const myTurnBannerVaccineNames = [];
    for (let i = 0; i < myTurnBannerGroupCodes.length; i++) {
      const code = myTurnBannerGroupCodes[i];

      if (code in settings.MyTurnBannerVaccineGroups) {
        const vaccineName = settings.MyTurnBannerVaccineGroups[code];
        myTurnBannerVaccineNames.push(vaccineName);
      }
    }

    // list vaccines alphabetically
    myTurnBannerVaccineNames.sort();

    for (let i = 0; i < myTurnBannerVaccineNames.length; i++) {
      const vaccineName = myTurnBannerVaccineNames[i];
      if (i === 0) {
        bannerMessage += vaccineName;
      } else {
        bannerMessage += `, ${vaccineName}`;
      }
    }

    return bannerMessage;
  };

  return (
    settings.MyTurnBannerStatus &&
    !isAlertClosed &&
    getMyTurnBannerMessage() !== '' && (
      <Paper className={BannerCss.rootMyTurn} square elevation={0}>
        <div className={BannerCss.bannerWrapper}>
          <img
            className={BannerCss.alertImage}
            aria-hidden="true"
            src="/imgs/myturn-banner.svg"
            alt=""
          />
          <div className={BannerCss.textContainer}>
            <span className={BannerCss.body}>
              <strong>Overdue and Upcoming Vaccinations: </strong>
              {getMyTurnBannerMessage()}
            </span>
            <br />
            <span className={BannerCss.body}>
              Visit{' '}
              <a
                href={settings.MyTurnLink}
                target="_blank"
                style={{
                  color: '#0d6efd',
                  textDecorationLine: 'underline',
                }}
                rel="noreferrer"
              >
                My Turn
              </a>{' '}
              to schedule your next vaccine appointment.
            </span>
          </div>
          <button
            className={BannerCss.close}
            type="button"
            onClick={() => closeBanner()}
          >
            <img
              src="/imgs/x-icon-banner.svg"
              aria-label="close recommended vaccinations banner"
              alt=""
            />
          </button>
        </div>
      </Paper>
    )
  );
}
export default MyTurnBanner;
