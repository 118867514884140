import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useLocation } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import '../styles/FaqPage-style.css';
import { useTranslation } from 'react-i18next';
import { appInsights } from '../utils/ApplicationInsightsService';

// import faqJson from "./DVRQAContent.json";
import faqEn from './faq-locales/faq-en.json';
import faqEs from './faq-locales/faq-es.json';
import faqCn from './faq-locales/faq-cn.json';
import faqKr from './faq-locales/faq-kr.json';
import faqPh from './faq-locales/faq-ph.json';
import faqTw from './faq-locales/faq-tw.json';
import faqVi from './faq-locales/faq-vi.json';
import faqAe from './faq-locales/faq-ae.json';

/* eslint-disable no-unused-vars */

function FaqPage({ _setHtmlDir, _htmlDir, settings }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [openTabList, setOpenTabList] = useState(true);
  const [mobileTabDisplay, setMobileTabDisplay] = useState('');
  const { _t, i18n } = useTranslation();

  // for FAQ params
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramLanguage = searchParams.get('lang');

  const [openSections, setOpenSections] = useState({ 0: true });
  const [showFaqAnswer, setShowFaqAnswer] = useState(false);

  const { search } = useLocation();

  const faqJsonMap = {
    en: faqEn,
    es: faqEs,
    cn: faqCn,
    kr: faqKr,
    ph: faqPh,
    tw: faqTw,
    vi: faqVi,
    ae: faqAe,
  };

  // Get the current language
  const currentLanguage = i18n.language;

  // Get the appropriate FAQ JSON based on the current language
  const faqJson = faqJsonMap[currentLanguage] || faqEn;
  useEffect(() => {
    const match = search.match(/tab=(.*)/);
    const tab = match?.[1];

    if (tab) {
      setTabIndex(Number(tab));
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    } /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  // change language according to FAQ param language
  useEffect(() => {
    i18n.changeLanguage(paramLanguage);
  }, [paramLanguage]);

  useEffect(() => {
    setVATLinks();
  }, [settings]);

  useEffect(() => {
    setVATLinks();
  });

  const handleTabChange = (index) => {
    setTabIndex(index);
    setOpenSections({ 0: true });
  };

  const tabListClicked = () => {
    setOpenTabList(!openTabList);
  };

  const handleMobileDropdownDisplay = (title) => {
    setMobileTabDisplay(title);
  };

  const handleQuestionClick = (faqIndex, question) => {
    const prevState = openSections[faqIndex];
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [faqIndex]: !prevOpenSections[faqIndex], // Toggle the state for the clicked FAQ section
    }));
    if (!prevState) {
      appInsights.trackEvent({ name: `Question Clicked - ${question}` });
    }
  };

  function setVATLinks() {
    const elements = document.getElementsByClassName('VirtualAssistantLink');
    for (let i = 0; i < elements.length; i++) {
      elements[i].href = settings.VATLink;
    }
  }

  return (
    <div>
      <main className="main" id="main-content">
        <div
          className={
            settings.MaintenanceBannerClosed
              ? 'cookieCrumb'
              : 'cookieCrumbBanner'
          }
        >
          <ol className="breadcrumb">
            <li>
              <a href="/">Vaccine Record Home</a>
            </li>
            <li>Q&A</li>
          </ol>
        </div>
        <div className="QAndAContainer">
          <div className="infoBoxContainerDesktop">
            {faqJson.vaccineTitles.map(
              (vaccineOption, index) =>
                tabIndex === index && (
                  <div className="infoBox">
                    <div className="infoBoxTitle">{vaccineOption.title}</div>
                  </div>
                ),
            )}
          </div>

          <Tabs selectedIndex={tabIndex} onSelect={handleTabChange}>
            <div className="desktopTabs">
              <TabList aria-label="Q&A Tabs">
                {faqJson.vaccineTabs.map((vaccineOption, index) =>
                  index === 2 && currentLanguage === 'vi' ? (
                    <Tab
                      lang="en-us"
                      style={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      <div>{vaccineOption.title}</div>
                    </Tab>
                  ) : index === 2 &&
                    (currentLanguage === 'es' ||
                      currentLanguage === 'ph' ||
                      currentLanguage === 'vi') ? (
                    <Tab
                      lang="en-us"
                      style={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      <span>{vaccineOption.title}</span>
                    </Tab>
                  ) : (
                    <Tab
                      lang="en-us"
                      style={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      {vaccineOption.title}
                    </Tab>
                  ),
                )}
              </TabList>
            </div>

            {/* mobile tabs */}
            <div className="mobileTabs">
              <div
                className="tabMenu"
                role="button"
                tabIndex={0}
                onClick={tabListClicked}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    tabListClicked();
                  }
                }}
              >
                {faqJson.vaccineTitles[tabIndex].title}
                {openTabList ? (
                  <span className="downArrow" id="downArrow" />
                ) : (
                  <span className="arrow" id="upArrow" />
                )}
              </div>

              {!openTabList && (
                <TabList>
                  {faqJson.vaccineTitles.map((vaccineOption) => (
                    <Tab
                      lang="en-us"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleMobileDropdownDisplay(vaccineOption.title);
                        tabListClicked();
                      }}
                    >
                      {vaccineOption.title}
                    </Tab>
                  ))}
                </TabList>
              )}

              <div className="infoBoxContainerMobile">
                {faqJson.vaccineTitles.map(
                  (vaccineOption, index) =>
                    tabIndex === index && (
                      <div className="infoBox">
                        <div className="infoBoxTitle">
                          {vaccineOption.title}
                        </div>
                      </div>
                    ),
                )}
              </div>
            </div>

            {faqJson.vaccineFaq.map((vaccineFaqList, index) => (
              <TabPanel key={index}>
                {vaccineFaqList.list.map((vaccineFaqOption, optionIndex) => (
                  <div className="faqWrapper" key={optionIndex}>
                    <div className="faqItem">
                      <div
                        className="questionWrapper"
                        role="button"
                        tabIndex={0}
                        onClick={() =>
                          handleQuestionClick(
                            optionIndex,
                            vaccineFaqOption.question,
                          )
                        }
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === ' ') {
                            handleQuestionClick(
                              optionIndex,
                              vaccineFaqOption.question,
                            );
                          }
                        }}
                      >
                        <p
                          style={{ fontWeight: 'bold' }}
                          // onClick={() => handleQuestionClick(optionIndex)}
                        >
                          {vaccineFaqOption.question}
                        </p>
                        <button
                          type="button" // Specify the type attribute
                          // onClick={() => handleQuestionClick(optionIndex)}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event from bubbling up to the parent
                            handleQuestionClick(optionIndex);
                          }}
                          className="toggle"
                          aria-label="Toggle answer display"
                        >
                          {openSections[optionIndex]
                            ? String.fromCharCode(0x2013)
                            : '+'}
                        </button>
                      </div>
                      <div
                        style={{
                          height: openSections[optionIndex] ? 'auto' : '0px',
                          display: openSections[optionIndex] ? 'block' : 'none',
                        }}
                        className="answer"
                      >
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: vaccineFaqOption.answer,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <br />
                <br />
                <br />
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </main>
      <hr />
    </div>
  );
}

export default FaqPage;
