import React from 'react';
import { Trans } from 'react-i18next';
import { PDFDownloadLink } from '@react-pdf/renderer';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { Button } from '@material-ui/core';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Header from './Header';
import PDFFile from './PDFFile';

/* eslint-disable-no-unused-vars */

function RecordDataHeader({
  isDesktop,
  record,
  displayVaccineGroupName,
  displayEvaluatorStatus,
  htmlDir,
  setHtmlDir,
}) {
  const appInsights = useAppInsightsContext();

  const pressPDFHeader = () => {
    appInsights.trackEvent({ name: 'DVR Record Download as PDF Pressed' });
  };
  return isDesktop ? (
    // desktop layout
    <div className="margin-container">
      <h1 className="title-style">
        <Trans i18nKey="recordpage.title">California Immunization Record</Trans>
      </h1>
      {isDesktop && <br />}
      <div className="personal-info-container">
        <div>
          <span>
            <span style={{ fontWeight: 'bold' }}>Name: </span>
            {record.firstName} {record.lastName}{' '}
          </span>
          <br />
          <br />
          <span>
            <span style={{ fontWeight: 'bold' }}>Date of Birth: </span>
            {record.dob}
          </span>
        </div>
        <div>
          <span>
            <span style={{ fontWeight: 'bold' }}>Record Issued On: </span>
            {record.currentDate}
          </span>
        </div>
      </div>
    </div>
  ) : (
    // mobile layout
    <div className="record-header-container-mobile">
      <Header setHtmlDir={setHtmlDir} htmlDir={htmlDir} />
      <div className="margin-container">
        <h1
          className="title-style"
          style={{ fontSize: '25px', textAlign: 'center' }}
        >
          <Trans i18nKey="recordpage.title">
            California Immunization Record
          </Trans>
        </h1>
        {isDesktop && <br />}
        <div className="personal-info-container-mobile">
          <div className="personal-info-text-mobile">
            <span>
              <span style={{ fontWeight: 'bold' }}>Name: </span>
              {record.firstName} {record.lastName}{' '}
            </span>
            <span>
              <span style={{ fontWeight: 'bold' }}>Date of Birth: </span>
              {record.dob}
            </span>
            <span>
              <span style={{ fontWeight: 'bold' }}>Record Issued On: </span>
              {record.currentDate}
            </span>
          </div>
          <div>
            <PDFDownloadLink
              document={
                <PDFFile
                  record={record}
                  displayVaccineGroupName={displayVaccineGroupName}
                  displayEvaluatorStatus={displayEvaluatorStatus}
                />
              }
              fileName="digitalvaccinerecord.pdf"
            >
              <Button
                id="personal-info-download-button-mobile"
                children={<SaveAltIcon />}
                size="large"
                onClick={(_e) => {
                  pressPDFHeader();
                }}
              />
            </PDFDownloadLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecordDataHeader;
